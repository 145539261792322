import { ApiTriggerType, PageDSL8 } from "@superblocksteam/shared";
import { generateReactKey } from "legacy/utils/generators";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import logger from "utils/logger";
import type { AuxiliaryData as ExtraData } from "../MigratePage";

export const SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE = "optimizedPageLoadApis";
export type PageLoadAction = {
  id: string;
  type: typeof SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE;
  apiNames: string[];
  includeLegacyAPIs?: true;
};

export default function migrate5to6(
  page: PageDSL8,
  extraData: ExtraData,
): PageDSL8 {
  const hasCachedData = !!page.cachedData?.pageLoadActions;
  const hasAutoAPIs =
    extraData.apis &&
    Object.values(extraData.apis).some(
      (api) =>
        api.apiPb.trigger.application?.options?.executeOnPageLoad === undefined,
    );

  let pageLoadAction: PageLoadAction;
  if (hasCachedData) {
    pageLoadAction = createPageLoadAction(fromCachedData(page));
  } else if (!hasAutoAPIs) {
    pageLoadAction = createPageLoadAction(fromApis(extraData));
  } else {
    pageLoadAction = generateLegacyPageLoad();
    logger.info({
      message:
        "Page has auto apis but no cached data, will use legacy page load",
      pageId: page.pageId,
    });
  }
  page.onPageLoad = [pageLoadAction];
  return page;
}

const createPageLoadAction = (
  apiNames: string[],
  includeLegacyAPIs?: boolean,
): PageLoadAction => {
  return {
    id: generateReactKey(),
    type: SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE,
    apiNames,
    ...(includeLegacyAPIs && { includeLegacyAPIs }),
  };
};

const generateLegacyPageLoad = (): PageLoadAction => {
  return createPageLoadAction([], true);
};

const fromApis = (extraData: ExtraData): string[] => {
  return Object.values(extraData.apis)
    .filter(
      (api) =>
        api.apiPb.trigger.application?.options?.executeOnPageLoad === true,
    )
    .map((api) => getV2ApiName(api));
};

const fromCachedData = (page: PageDSL8): string[] => {
  return page.cachedData?.pageLoadActions?.apiNames ?? [];
};

// This is a cloned function, we never wish to import in migrations
const getV2ApiName = (api: ApiDtoWithPb): string => {
  if (api.triggerType !== ApiTriggerType.UI) {
    return api.name ?? api.apiPb?.metadata?.name ?? api?.name ?? "";
  }
  return api.apiPb?.metadata?.name ?? api?.name ?? "";
};
