import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  showInsertUIBlocksModal: boolean;
} = {
  showInsertUIBlocksModal: false,
};

export const uiBlocksSlice = createSlice({
  name: "uiBlocks",
  initialState,
  reducers: {
    showUiBlocksModal: (state) => {
      state.showInsertUIBlocksModal = true;
    },
    closeUiBlocksModal: (state) => {
      state.showInsertUIBlocksModal = false;
    },
  },
});

export const { showUiBlocksModal, closeUiBlocksModal } = uiBlocksSlice.actions;
