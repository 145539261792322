import equal from "@superblocksteam/fast-deep-equal/es6";
import { ApplicationScope, WidgetTypes } from "@superblocksteam/shared";
import {
  all,
  call,
  getContext,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { put, select } from "redux-saga/effects";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  closeEditorTab,
  editorPreferencesError,
  openEditorTab,
  updateEditorPreferences,
  updateFocusedItems,
} from "legacy/actions/editorPreferencesActions";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import { deleteWidgets, selectWidgets } from "legacy/actions/widgetActions";
import {
  EditorOpenTabType,
  PagePreferences,
} from "legacy/constants/EditorPreferencesConstants";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { EditorRoute } from "legacy/constants/routes";
import { getWidgetDisplayName } from "legacy/pages/Editor/Explorer/helpers";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import {
  getIsSidebarFocusedItemsTracked,
  getSidebarFocusedItems,
} from "legacy/selectors/editorPreferencesSelector";
import { getCurrentPageId } from "legacy/selectors/editorSelectors";
import { getOpenPropertyPanelItem } from "legacy/selectors/propertyPaneSelectors";
import { getCurrentRoutePathWithParams } from "legacy/selectors/routeSelectors";
import {
  getPageEditorPreferences,
  getSelectedWidgets,
  getWidget,
  getWidgets,
} from "legacy/selectors/sagaSelectors";
import { formatPropertyPathWithIndices } from "legacy/utils/BottomPanelTabUtils";
import { getTabId } from "legacy/utils/EditorPreferencesUtils";
import { isTabPropertyValid } from "legacy/utils/EditorPreferencesUtils";
import { deleteV1ApiSaga } from "store/slices/apisShared";
import { deleteV2ApiSaga, selectV2ApiById } from "store/slices/apisV2";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import { getCurrentApplication } from "store/slices/application/selectors";
import { getStateVarById } from "store/slices/application/stateVars/selectors";
import { getTimerById } from "store/slices/application/timers/selectors";
import { fastClone } from "utils/clone";

const initEditorPreferencesForPage = (
  existingPreferences: PagePreferences | undefined,
) => {
  if (!existingPreferences) {
    existingPreferences = {
      openBottomPanelTabs: [],
    };
  }
  return existingPreferences;
};

function* getPreferences(
  applicationId: string,
  pageId: string,
): Generator<any, PagePreferences, any> {
  let preferences: PagePreferences = yield select(
    getPageEditorPreferences,
    applicationId,
    pageId,
  );
  if (!preferences) {
    preferences = { openBottomPanelTabs: [] };
  } else {
    preferences = fastClone(initEditorPreferencesForPage(preferences));
  }

  return preferences;
}

function* openEditorTabSaga(
  action: ReturnType<typeof openEditorTab>,
): Generator<any, any, any> {
  try {
    const { tabType, entityId, property, strict } = action.payload;

    const application: ReturnType<typeof getCurrentApplication> = yield select(
      getCurrentApplication,
    );
    const pageId = yield select(getCurrentPageId);
    if (!application) throw new Error("No current application");

    // Confirm this tab maps to a real widget property
    if (tabType === EditorOpenTabType.WIDGET) {
      const widget = yield select(getWidget, entityId);
      const path = formatPropertyPathWithIndices(widget, property);

      if (!widget || !property || !isTabPropertyValid(widget, path, strict)) {
        throw new Error("Widget property not found for new tab");
      }
    }
    const currentRoute = yield select(getCurrentRoutePathWithParams);

    // ensure that the tab is visible, even if it was already available in tab bar
    if (tabType === EditorOpenTabType.API) {
      const navigate = yield getContext("navigate");
      const route = action.payload.actionId
        ? EditorRoute.EditApiAction
        : EditorRoute.EditApi;
      const path = getEditorBasePath(route, {
        applicationId: application.id,
        apiId: entityId,
        actionId: action.payload.actionId,
        currentRoute,
      });
      navigate(path + window.location.search);
    }

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    const openBottomPanelTabsIds = preferences.openBottomPanelTabs.map(
      (tab) => tab.id,
    );

    const newTabId = getTabId({
      ...action.payload,
      widgetProperty: property,
      entityType: tabType,
    });

    // Disallow opening the same type of tab twice
    if (openBottomPanelTabsIds.includes(newTabId)) return;

    // widgetProperty is the name due to backwards compatibility with whats stored in localStorage
    preferences.openBottomPanelTabs = [
      ...preferences.openBottomPanelTabs,
      {
        id: newTabId,
        entityType: tabType,
        entityId,
        ...(property ? { widgetProperty: property } : {}),
      },
    ];

    yield put(
      updateEditorPreferences({
        applicationId: application.id,
        pageId,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

function* closeEditorTabSaga(
  action: ReturnType<typeof closeEditorTab>,
): Generator<any, any, any> {
  try {
    const tabIdToClose = action.payload.id;

    const application = yield select(getCurrentApplication);
    const pageId = yield select(getCurrentPageId);
    if (!application) {
      throw new Error("No current application");
    }

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    preferences.openBottomPanelTabs = preferences.openBottomPanelTabs.filter(
      (tab) => {
        return tab.id !== tabIdToClose;
      },
    );

    yield put(
      updateEditorPreferences({
        pageId,
        applicationId: application.id,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

function* closeEditorTabsForDeletedWidgets(
  action: ReturnType<typeof deleteWidgets>,
): Generator<any, any, any> {
  try {
    const { widgetIds } = action.payload;
    const application = yield select(getCurrentApplication);
    const pageId = yield select(getCurrentPageId);

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    preferences.openBottomPanelTabs = preferences.openBottomPanelTabs.filter(
      (tab) => {
        return !(
          tab.entityType === "WIDGET" &&
          (widgetIds || []).includes(tab.entityId)
        );
      },
    );

    yield put(
      updateEditorPreferences({
        pageId,
        applicationId: application.id,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

function* closeEditorTabsForDeletedApi(
  action: ReduxAction<{
    id: string;
  }>,
): Generator<any, any, any> {
  try {
    const { id } = action.payload;
    const application = yield select(getCurrentApplication);
    const pageId = yield select(getCurrentPageId);

    if (!application) {
      // We can delete apis from the homepage, so we don't always have an application
      return;
    }

    const preferences: PagePreferences = yield call(
      getPreferences,
      application.id,
      pageId,
    );

    preferences.openBottomPanelTabs = preferences.openBottomPanelTabs.filter(
      (tab) => {
        return !(tab.entityType === "API" && tab.entityId === id);
      },
    );

    yield put(
      updateEditorPreferences({
        pageId,
        applicationId: application.id,
        updatedPreferences: preferences,
      }),
    );
  } catch (error: any) {
    yield put(
      editorPreferencesError({ action: action.type, error: error.message }),
    );
  }
}

function* focusItemsOnTrack() {
  const isTracking: ReturnType<typeof getIsSidebarFocusedItemsTracked> =
    yield select(getIsSidebarFocusedItemsTracked);

  if (!isTracking) {
    return;
  }

  const focusedItems: ReturnType<typeof getSidebarFocusedItems> = yield select(
    getSidebarFocusedItems,
  );
  const selectedItem: ReturnType<typeof getOpenPropertyPanelItem> =
    yield select(getOpenPropertyPanelItem);
  const selectedWidgets: ReturnType<typeof getSelectedWidgets> = yield select(
    getSelectedWidgets,
  );

  if (selectedWidgets && selectedWidgets.length > 1) {
    const newFocusedItems = selectedWidgets.map((widget) => ({
      type: ItemKinds.WIDGET,
      name: widget.widgetName,
      scope: ApplicationScope.PAGE,
      id: widget.widgetId,
    }));

    if (equal(focusedItems, newFocusedItems)) {
      return;
    }

    yield put(
      updateFocusedItems({
        focusedItems: newFocusedItems,
      }),
    );
  } else if (selectedItem) {
    let itemName = "";

    if (selectedItem.kind === ItemKinds.WIDGET) {
      const widgets: ReturnType<typeof getWidgets> = yield select(getWidgets);
      const widget: ReturnType<typeof getWidget> = yield select(
        getWidget,
        selectedItem.id,
      );
      const parentWidget: ReturnType<typeof getWidget> | undefined =
        widget?.parentId ? yield select(getWidget, widget.parentId) : undefined;

      const isSectionWidget = widget?.type === WidgetTypes.SECTION_WIDGET;
      const isColumnWidget =
        widget?.type === WidgetTypes.CANVAS_WIDGET &&
        parentWidget?.type === WidgetTypes.SECTION_WIDGET;
      const isPageWidget = widget?.type === WidgetTypes.PAGE_WIDGET;
      if (isSectionWidget || isColumnWidget || isPageWidget) {
        return;
      }

      if (widget) {
        const displayName = getWidgetDisplayName(
          widget,
          widgets[widget.parentId],
        );
        itemName = displayName ?? widget?.widgetName ?? "";
      }
    } else if (selectedItem.kind === ItemKinds.API_V2) {
      const api: ReturnType<typeof selectV2ApiById> = yield select(
        selectV2ApiById,
        selectedItem.id,
      );
      itemName = api ? getV2ApiName(api) : "";
    } else if (selectedItem.kind === ItemKinds.STATE_VAR) {
      const stateVar: ReturnType<typeof getStateVarById> = yield select(
        getStateVarById,
        selectedItem.id,
      );
      itemName = stateVar?.name ?? "";
    } else if (selectedItem.kind === ItemKinds.TIMER) {
      const timer: ReturnType<typeof getTimerById> = yield select(
        getTimerById,
        selectedItem.id,
      );
      itemName = timer?.name ?? "";
    }

    const newFocusedItems = [
      {
        type: selectedItem.kind,
        name: itemName,
        scope: selectedItem.scope,
        id: selectedItem.id,
      },
    ];

    if (equal(focusedItems, newFocusedItems)) {
      return;
    }

    yield put(
      updateFocusedItems({
        focusedItems: newFocusedItems,
      }),
    );
  }
}

export default function* editorPreferencesSagas() {
  yield all([
    takeEvery(openEditorTab.type, openEditorTabSaga),
    takeEvery(closeEditorTab.type, closeEditorTabSaga),
    takeEvery(deleteWidgets.type, closeEditorTabsForDeletedWidgets),
    takeEvery(deleteV1ApiSaga.success.type, closeEditorTabsForDeletedApi),
    takeEvery(deleteV2ApiSaga.success.type, closeEditorTabsForDeletedApi),
    takeLatest(
      [selectWidgets.type, showItemPropertyPane.type],
      focusItemsOnTrack,
    ),
  ]);
}
