import * as ContextMenu from "@radix-ui/react-context-menu";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { ApplicationScope } from "@superblocksteam/shared";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useStore } from "react-redux";
import { ReactComponent as ComponentIcon } from "assets/icons/common/component.svg";
import SBContextMenu, {
  Slot,
  RightSlot,
  ItemText,
  ContentWrapper,
  ContextMenuContentClass,
} from "components/ui/ContextMenu";
import { useFeatureFlag } from "hooks/ui";
import useClearSelectedWidgets from "hooks/ui/useClearSelectedWidgets";
import { useEditorHotkeys } from "hooks/ui/useEditorHotkeys";
import {
  usePasteWidget,
  getPasteAtCursorInsertionIndexes,
  type PasteInsertionIndexes,
} from "hooks/ui/usePasteWidget";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import {
  copyWidget,
  cutWidget,
  deleteSelectedWidget,
  focusWidget,
  groupSelectedWidgets,
} from "legacy/actions/widgetActions";
import { IconProps } from "legacy/constants/IconConstants";
import {
  WidgetTypes,
  PAGE_WIDGET_ID,
  WIDGET_CAN_HAVE_CHILDREN,
} from "legacy/constants/WidgetConstants";
import WIDGET_DOC_URLS from "legacy/constants/WidgetDocUrls";
import { useWidgetSelection } from "legacy/hooks/dragResizeHooks";
import { getWidgetIcon } from "legacy/icons/WidgetIcons";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import {
  getSelectedWidgetsAncestryWithNames,
  selectWidgetDisplayName,
  getFirstSectionFirstColumn,
  getWidgets,
  getIsMultipleWidgetsSelected,
  getSelectedWidgets,
} from "legacy/selectors/sagaSelectors";
import { getCopiedWidgets } from "legacy/utils/StorageUtils";
import {
  isUiBlocksForUsersFlagEnabled,
  UI_BLOCKS_FOR_USERS_FLAG_TYPE,
} from "pages/Editors/UiBlocks/utils";
import { useAppSelector } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import { showUiBlocksModal } from "store/slices/uiBlocks/slice";
import { AppState } from "store/types";
import { sendMessage } from "utils/iframe";
import {
  getGroupIntoContainerShortcutString,
  getShortcutString,
} from "utils/navigator";
import widgetContextMenuEventBus, {
  WidgetContextMenuEvents,
} from "./WidgetContextMenuEventBus";

type WidgetContextMenuClickEventDetails = {
  widgetId: string;
  clientX: number;
  clientY: number;
  pasteAtCursorInsertionIndexes?: PasteInsertionIndexes;
};

const TRIGGER_ID = "WidgetContextMenuTrigger";

export const useOpenContextMenuForWidget = () => {
  const store = useStore<AppState>();

  const openContextMenuForWidgetLocal = useCallback(
    ({
      widgetId,
      isInsideIframe,
      clientX,
      clientY,
      pasteAtCursorInsertionIndexes,
    }: {
      widgetId: string;
      isInsideIframe?: boolean;
      clientX: number;
      clientY: number;
      pasteAtCursorInsertionIndexes?: PasteInsertionIndexes;
    }) => {
      let pasteAtCursorInsertionIndexesDefaulted =
        pasteAtCursorInsertionIndexes;

      if (!pasteAtCursorInsertionIndexes) {
        const state = store.getState();
        const focusedWidget = state.legacy.ui.widgetDragResize.focusedWidgetId;
        const selectedWidgetIds =
          state.legacy.ui.widgetDragResize.selectedWidgets;
        const pasteTargetIdDefaulted =
          widgetId ?? selectedWidgetIds[0] ?? PAGE_WIDGET_ID;
        const widgets = getWidgets(state);

        pasteAtCursorInsertionIndexesDefaulted =
          getPasteAtCursorInsertionIndexes({
            focusedWidget,
            pasteTargetId: pasteTargetIdDefaulted,
            mousePosition: {
              x: clientX,
              y: clientY,
            },
            widgets,
          });
      }

      openContextMenuForWidget({
        widgetId,
        isInsideIframe,
        clientX,
        clientY,
        pasteAtCursorInsertionIndexes: pasteAtCursorInsertionIndexesDefaulted,
      });
    },
    [store],
  );

  return openContextMenuForWidgetLocal;
};

const openContextMenuForWidget = ({
  widgetId,
  isInsideIframe,
  clientX,
  clientY,
  pasteAtCursorInsertionIndexes,
}: {
  widgetId: string;
  isInsideIframe?: boolean;
  clientX: number;
  clientY: number;
  pasteAtCursorInsertionIndexes?: PasteInsertionIndexes;
}) => {
  const payload = {
    widgetId,
    clientX,
    clientY,
    pasteAtCursorInsertionIndexes,
  };

  if (isInsideIframe) {
    sendMessage({
      type: "open-widget-context-menu",
      payload,
    });
  } else {
    widgetContextMenuEventBus.dispatchEvent(
      new CustomEvent<WidgetContextMenuClickEventDetails>(
        WidgetContextMenuEvents.CONTEXT_MENU_CLICK,
        {
          detail: payload,
        },
      ),
    );
  }
};

const PageIcon: React.JSXElementConstructor<IconProps> = getWidgetIcon(
  WidgetTypes.PAGE_WIDGET,
);

const PageOptionContent = (
  <Slot>
    <PageIcon width={16} height={16} />
    <ItemText>Page</ItemText>
  </Slot>
);

const WidgetContextMenu: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const [widgetId, setWidgetId] = useState<string | null>(null);
  const [pasteAtCursorInsertionIndexes, setPasteAtCursorInsertionIndexes] =
    useState<PasteInsertionIndexes | undefined>(undefined);
  const mouseCoordsAtOpen = useRef<{ x: number; y: number } | null>(null);
  const isOpen = !!widgetId;
  const [copiedWidgetsInfo, setCopiedWidgetsInfo] = useState<{
    isSections: boolean;
    isColumns: boolean;
    widgetIds: string[];
  } | null>(null);

  const enableGrouping = useFeatureFlag(Flag.LAYOUTS_ENABLE_GROUPING);
  const uiBlocksForUsersFlag = useFeatureFlag(
    Flag.UI_BLOCKS_FOR_USERS,
  ) as UI_BLOCKS_FOR_USERS_FLAG_TYPE;

  const { selectWidgets } = useWidgetSelection();

  const selectedWidgetsAncestryWithNames = useAppSelector((state) =>
    getSelectedWidgetsAncestryWithNames(state, widgetId || ""),
  );

  const firstSectionColumnWidget = useAppSelector(getFirstSectionFirstColumn);

  const widgetDisplayName = useAppSelector((state) =>
    selectWidgetDisplayName(state, widgetId || ""),
  );

  const widget = useAppSelector((state) =>
    getFlattenedCanvasWidget(state, widgetId || ""),
  );

  const parentWidget = useAppSelector((state) =>
    getFlattenedCanvasWidget(state, widget?.parentId || ""),
  );

  const parentWidgetDisplayName = useAppSelector((state) =>
    selectWidgetDisplayName(state, widget?.parentId || ""),
  );

  const isMultipleWidgetsSelected = useAppSelector(
    getIsMultipleWidgetsSelected,
  );
  const selectedWidgets = useAppSelector(getSelectedWidgets);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setWidgetId(null);
    }
  }, []);

  const handleContextMenuClick = useCallback(
    async (e: CustomEvent<WidgetContextMenuClickEventDetails>) => {
      setWidgetId(e.detail.widgetId);
      setPasteAtCursorInsertionIndexes(e.detail.pasteAtCursorInsertionIndexes);

      // Adjust the x,y based on iframe location
      const iframe = document.querySelector('[data-test="sb-iframe"]');
      if (!iframe) return;
      const rect = iframe.getBoundingClientRect();
      mouseCoordsAtOpen.current = {
        x: e.detail.clientX - rect.left,
        y: e.detail.clientY - rect.top,
      };

      const copiedWidgets = await getCopiedWidgets();
      if (copiedWidgets) {
        const isEmpty = copiedWidgets.length === 0;
        setCopiedWidgetsInfo({
          isSections:
            !isEmpty && copiedWidgets[0].type === WidgetTypes.SECTION_WIDGET,
          isColumns:
            !isEmpty &&
            copiedWidgets[0].type === WidgetTypes.CANVAS_WIDGET &&
            copiedWidgets[0].parentType === WidgetTypes.SECTION_WIDGET,
          widgetIds: copiedWidgets?.map((widget) => widget.widgetId) || [],
        });
      } else {
        setCopiedWidgetsInfo(null);
      }

      setTimeout(() => {
        const trigger = document.getElementById(TRIGGER_ID);
        if (!trigger) return;
        trigger.dispatchEvent(
          new MouseEvent("contextmenu", {
            bubbles: true,
            clientX: e.detail.clientX,
            clientY: e.detail.clientY,
          }),
        );
      }, 0);
    },
    [setWidgetId, setPasteAtCursorInsertionIndexes],
  );

  useEffect(() => {
    widgetContextMenuEventBus.addEventListener(
      WidgetContextMenuEvents.CONTEXT_MENU_CLICK,
      handleContextMenuClick as unknown as EventListenerOrEventListenerObject,
    );

    return () => {
      widgetContextMenuEventBus.removeEventListener(
        WidgetContextMenuEvents.CONTEXT_MENU_CLICK,
        handleContextMenuClick as unknown as EventListenerOrEventListenerObject,
      );
    };
  }, [handleContextMenuClick]);

  const handleCopy = useCallback(() => {
    dispatch(copyWidget(false));
  }, [dispatch]);

  const handleCut = useCallback(() => {
    dispatch(cutWidget());
  }, [dispatch]);

  const handlePasteWidget = usePasteWidget();
  const handlePaste = useCallback(
    (e: React.MouseEvent, pasteAtCursor = false) => {
      if (widget?.widgetId) {
        handlePasteWidget({
          pasteTargetId:
            !copiedWidgetsInfo?.isSections &&
            !copiedWidgetsInfo?.isColumns &&
            widget?.widgetId === PAGE_WIDGET_ID
              ? firstSectionColumnWidget?.widgetId
              : widget?.widgetId,
          pasteAtCursor,
          forcePasteIntoContainer: true,
          mousePosition:
            pasteAtCursor && mouseCoordsAtOpen.current
              ? mouseCoordsAtOpen.current
              : undefined,
          pasteAtCursorInsertionIndexes,
        });
      }
    },
    [
      widget?.widgetId,
      handlePasteWidget,
      copiedWidgetsInfo?.isColumns,
      copiedWidgetsInfo?.isSections,
      firstSectionColumnWidget?.widgetId,
      pasteAtCursorInsertionIndexes,
    ],
  );

  const handlePasteWidgetAtCursor = useCallback(
    (e: React.MouseEvent) => {
      handlePaste(e, true);
    },
    [handlePaste],
  );

  const clearSelectedWidgets = useClearSelectedWidgets();

  const handleDelete = useCallback(() => {
    dispatch(deleteSelectedWidget(true));
  }, [dispatch]);

  const handleViewState = useCallback(() => {
    if (!widget) return;

    let focusedItems = [
      {
        type: ItemKinds.WIDGET,
        name: widget.widgetName,
        scope: ApplicationScope.PAGE,
        id: widget.widgetId,
      },
    ];

    if (isMultipleWidgetsSelected) {
      focusedItems = selectedWidgets.map((widget) => ({
        type: ItemKinds.WIDGET,
        name: widget.widgetName,
        scope: ApplicationScope.PAGE,
        id: widget.widgetId,
      }));
    }

    dispatch(
      updateApplicationSidebarKey({
        selectedKey: SideBarKeys.NAVIGATION,
        focusedItems,
      }),
    );
  }, [dispatch, isMultipleWidgetsSelected, selectedWidgets, widget]);

  const handleGroupingWidgets = useCallback(() => {
    dispatch(groupSelectedWidgets());
  }, [dispatch]);

  const handleInsertUIBlock = useCallback(() => {
    dispatch(showUiBlocksModal());
  }, [dispatch]);

  const docsUrl = widget ? WIDGET_DOC_URLS[widget.type] : null;

  const goToDocs = useCallback(() => {
    if (!widget || !docsUrl) return;

    window.open(docsUrl, "_blank");
  }, [widget, docsUrl]);

  useEditorHotkeys(
    "delete, backspace",
    () => {
      dispatch(deleteSelectedWidget(true));
    },
    {
      filterPreventDefault: false,
      enabled: isOpen,
    },
  );

  if (!widget) return null;

  const allowPaste = copiedWidgetsInfo
    ? copiedWidgetsInfo.widgetIds?.length > 0
    : false;

  const CurrentWidgetIcon: React.JSXElementConstructor<IconProps> =
    getWidgetIcon(widget.type);

  let pasteOptionLabel = "Paste";

  const widgetCanHaveChildren = WIDGET_CAN_HAVE_CHILDREN.includes(
    widget.type as WidgetTypes,
  );

  const isSectionWidget = widget.type === WidgetTypes.SECTION_WIDGET;
  const isColumnWidget =
    widget.type === WidgetTypes.CANVAS_WIDGET &&
    parentWidget?.type === WidgetTypes.SECTION_WIDGET;
  const showViewState = !isSectionWidget && !isColumnWidget;

  const parentIsPageOrSection =
    parentWidget?.type === WidgetTypes.PAGE_WIDGET ||
    parentWidget?.type === WidgetTypes.SECTION_WIDGET;

  if (copiedWidgetsInfo?.isSections) {
    pasteOptionLabel = "Paste into Page";
  } else if (copiedWidgetsInfo?.isColumns) {
    pasteOptionLabel = "Paste into Section";
  } else if (widgetCanHaveChildren || isColumnWidget) {
    pasteOptionLabel = `Paste into ${widgetDisplayName}`;
  } else if (parentWidget && !parentIsPageOrSection) {
    pasteOptionLabel = `Paste into ${parentWidgetDisplayName}`;
  }

  const groupShortcut = getGroupIntoContainerShortcutString();

  return (
    <>
      <SBContextMenu
        contextMenuId={TRIGGER_ID}
        headerText={
          isMultipleWidgetsSelected ? "Multiple selected" : widgetDisplayName
        }
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        HeaderIcon={
          isMultipleWidgetsSelected ? ComponentIcon : CurrentWidgetIcon
        }
        trigger={
          // Use an invisible default trigger if one is not passed in
          children ?? <div style={{ width: 0, height: 0, display: "none" }} />
        }
      >
        <>
          <ContextMenu.Item onClick={handleCopy}>
            Copy <RightSlot>{getShortcutString(["C"])}</RightSlot>
          </ContextMenu.Item>
          <ContextMenu.Item onClick={handleCut}>
            Cut <RightSlot>{getShortcutString(["X"])}</RightSlot>
          </ContextMenu.Item>
          {allowPaste && (
            <ContextMenu.Item onClick={handlePaste}>
              <ItemText>{pasteOptionLabel}</ItemText>{" "}
              <RightSlot>{getShortcutString(["V"])}</RightSlot>
            </ContextMenu.Item>
          )}
          {allowPaste && (
            <ContextMenu.Item onClick={handlePasteWidgetAtCursor}>
              Paste at cursor
            </ContextMenu.Item>
          )}
          {allowPaste &&
            copiedWidgetsInfo &&
            !copiedWidgetsInfo.isSections &&
            !copiedWidgetsInfo.isColumns &&
            widget.type !== WidgetTypes.PAGE_WIDGET &&
            widget.type !== WidgetTypes.SECTION_WIDGET && (
              <ContextMenu.Sub>
                <ContextMenu.SubTrigger>
                  Paste at layer
                  <RightSlot>
                    <ChevronRightIcon />
                  </RightSlot>
                </ContextMenu.SubTrigger>
                <ContextMenu.Portal>
                  <ContextMenu.SubContent
                    className={`${ContextMenuContentClass}`}
                    sideOffset={10}
                  >
                    <ContentWrapper>
                      {(!copiedWidgetsInfo || !copiedWidgetsInfo.isSections) &&
                        selectedWidgetsAncestryWithNames.map(
                          (ancestryWidget, index) => {
                            if (
                              copiedWidgetsInfo?.isColumns &&
                              ancestryWidget.type !== WidgetTypes.SECTION_WIDGET
                            ) {
                              return false;
                            }

                            const AncestryWidgetIcon: React.JSXElementConstructor<IconProps> =
                              getWidgetIcon(ancestryWidget.type);
                            const isLast =
                              index ===
                              selectedWidgetsAncestryWithNames.length - 1;

                            return (
                              <ContextMenu.Item
                                key={ancestryWidget.widgetId}
                                onClick={() => {
                                  handlePasteWidget({
                                    pasteTargetId: ancestryWidget.widgetId,
                                  });
                                }}
                                onMouseEnter={() => {
                                  dispatch(
                                    focusWidget(ancestryWidget.widgetId),
                                  );
                                }}
                                onMouseLeave={() => {
                                  dispatch(focusWidget(widget.widgetId));
                                }}
                                className={isLast ? "selected" : ""}
                              >
                                <Slot>
                                  <AncestryWidgetIcon width={16} height={16} />

                                  <ItemText>
                                    {ancestryWidget.widgetName}
                                  </ItemText>
                                </Slot>
                              </ContextMenu.Item>
                            );
                          },
                        )}
                      {copiedWidgetsInfo?.isSections && (
                        <ContextMenu.Item
                          key={PAGE_WIDGET_ID}
                          onClick={() => {
                            handlePasteWidget({
                              pasteTargetId: PAGE_WIDGET_ID,
                            });
                          }}
                          onMouseEnter={() => {
                            dispatch(focusWidget(PAGE_WIDGET_ID));
                          }}
                          onMouseLeave={() => {
                            dispatch(focusWidget(widget.widgetId));
                          }}
                        >
                          {PageOptionContent}
                        </ContextMenu.Item>
                      )}
                    </ContentWrapper>
                  </ContextMenu.SubContent>
                </ContextMenu.Portal>
              </ContextMenu.Sub>
            )}
          {isMultipleWidgetsSelected && enableGrouping && (
            <ContextMenu.Item onClick={handleGroupingWidgets}>
              Group <RightSlot>{groupShortcut}</RightSlot>
            </ContextMenu.Item>
          )}

          {isUiBlocksForUsersFlagEnabled(uiBlocksForUsersFlag) && (
            <>
              <ContextMenu.Separator />
              <ContextMenu.Item onClick={handleInsertUIBlock}>
                Insert UI block
              </ContextMenu.Item>
            </>
          )}
          <ContextMenu.Separator />

          <ContextMenu.Sub>
            <ContextMenu.SubTrigger>
              Select layer
              <RightSlot>
                <ChevronRightIcon />
              </RightSlot>
            </ContextMenu.SubTrigger>
            <ContextMenu.Portal>
              <ContextMenu.SubContent
                className={`${ContextMenuContentClass}`}
                sideOffset={10}
              >
                <ContentWrapper>
                  <ContextMenu.Item
                    key={PAGE_WIDGET_ID}
                    onClick={() => {
                      selectWidgets([PAGE_WIDGET_ID]);
                    }}
                    onMouseEnter={() => {
                      dispatch(focusWidget(PAGE_WIDGET_ID));
                    }}
                    onMouseLeave={() => {
                      dispatch(focusWidget(widget.widgetId));
                    }}
                  >
                    {PageOptionContent}
                  </ContextMenu.Item>
                  {selectedWidgetsAncestryWithNames.map((ancestryWidget) => {
                    const AncestryWidgetIcon: React.JSXElementConstructor<IconProps> =
                      getWidgetIcon(ancestryWidget.type);

                    return (
                      <ContextMenu.Item
                        key={ancestryWidget.widgetId}
                        onClick={() => {
                          selectWidgets([ancestryWidget.widgetId]);
                        }}
                        onMouseEnter={() => {
                          dispatch(focusWidget(ancestryWidget.widgetId));
                        }}
                        onMouseLeave={() => {
                          dispatch(focusWidget(widget.widgetId));
                        }}
                      >
                        <Slot>
                          {AncestryWidgetIcon && (
                            <AncestryWidgetIcon width={16} height={16} />
                          )}
                          <ItemText>{ancestryWidget.widgetName}</ItemText>
                        </Slot>
                      </ContextMenu.Item>
                    );
                  })}
                  {widget?.type !== WidgetTypes.PAGE_WIDGET && (
                    <ContextMenu.Item
                      key={widget.widgetId}
                      onClick={() => {
                        selectWidgets([widget.widgetId]);
                      }}
                      className="selected"
                    >
                      <Slot>
                        <CurrentWidgetIcon width={16} height={16} />
                        <ItemText>{widgetDisplayName}</ItemText>
                      </Slot>
                    </ContextMenu.Item>
                  )}
                </ContentWrapper>
              </ContextMenu.SubContent>
            </ContextMenu.Portal>
          </ContextMenu.Sub>

          <ContextMenu.Separator />

          {showViewState && (
            <ContextMenu.Item onClick={handleViewState}>
              View state
            </ContextMenu.Item>
          )}
          {docsUrl && (
            <ContextMenu.Item onClick={goToDocs}>Go to docs</ContextMenu.Item>
          )}
          <ContextMenu.Item onClick={clearSelectedWidgets}>
            Unselect <RightSlot>Esc</RightSlot>
          </ContextMenu.Item>
          <ContextMenu.Item className="danger" onClick={handleDelete}>
            Delete <RightSlot>⌫</RightSlot>
          </ContextMenu.Item>
        </>
      </SBContextMenu>
    </>
  );
};

export default WidgetContextMenu;
