import { Agent, ApiResponseType, Organization } from "@superblocksteam/shared";
import { call, select, take } from "redux-saga/effects";
import {
  SUPERBLOCKS_UI_AGENT_BASE_URL,
  SUPERBLOCKS_UI_CLOUD_OPA_BASE_URL,
} from "env";

import { stopEvaluation } from "legacy/actions/evaluationActions";
import { getActiveAgents } from "store/slices/apisShared/utils";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { EntitiesErrorType } from "store/utils/types";

import { sendErrorUINotification } from "utils/notification";
import { marshalBlockInPlace } from "../../../../utils/marshalProto";
import { Action, PayloadActionWithMeta } from "../../../utils/action";
import { callSagas, createSaga, SagaActionMeta } from "../../../utils/saga";
import { ApiExecutionSagaPayload } from "../../apis/types";
import { selectOnlyOrganization } from "../../organizations";
import { orgIsOnPremise } from "../../organizations/utils";
import * as BackendTypes from "../backend-types";
import { executeV2Api } from "../client";

import { selectCachedControlFlowById } from "../control-flow/control-flow-selectors";
import { getBlockFromApi } from "../control-flow/getBlockFromApi";
import { selectV2ApiById, selectV2ApiMetaById } from "../selectors";
import slice, { type ApiDtoWithPb, type ApiV2Meta } from "../slice";
import { ExecutionResponse } from "../types";

import { getV2ApiId } from "../utils/getApiIdAndName";
import { computeEnrichedExecutionSaga } from "./computeEnrichedExecution";
import {
  getUserGeneratedInputs,
  processIntegrationConfig,
} from "./execute-block-utils";
import {
  authenticateFeDatasources,
  getMessageHandlers,
  getProfileAndUpdateParams,
} from "./execute-utils";

import { getV2ApiTestDataSaga } from "./getV2ApiTestData";
import { PersistApiPayload, persistV2ApiSaga } from "./persistV2Api";

type Props = ApiExecutionSagaPayload & {
  includeOutputs: boolean;
  blockName: string;
};

/* This map is used to keep track of ongoing API requests and allows for the ability to abort them if needed. */
const apiStepAbortMap = new Map<string, AbortController>();

class EvaluationError extends Error {
  evaluationErrors: unknown[];
  constructor(message: string, evaluationErrors: unknown[]) {
    super(message);
    this.evaluationErrors = evaluationErrors;
  }
}

function* executeV2ApiBlockInternal({
  apiId,
  blockName,
  environment,
  includeOutputs,
  params,
  notifyOnSystemError,
  commitId,
  eventType,
}: Props): Generator<unknown, undefined | ExecutionResponse, any> {
  const api: ReturnType<typeof selectV2ApiById> = yield select((state) =>
    selectV2ApiById(state, apiId),
  );
  if (!api) return;
  const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
    yield select((state) =>
      selectCachedControlFlowById(state, getV2ApiId(api)),
    );
  if (!controlFlow) return;
  const block = getBlockFromApi(controlFlow, blockName);
  if (!block) return;

  const organization: Organization = yield select(selectOnlyOrganization);
  const isOnPremise = orgIsOnPremise(organization);
  const enableProfiles: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES,
  );

  const { onMessage, processStreamEvents, parseFinalResult } = yield call(
    getMessageHandlers,
    apiId,
    includeOutputs,
    {
      mergeExecutionEvents: true,
      // TODO: When single block execution is available for control blocks,
      // we will need to recursively get all blocks within the block being run
      // and reinitialize all of them
      blocksToReinitialize: [blockName],
    },
    true, // isSingleBlock
  );

  const { profile, mode, profileId } = yield call(
    getProfileAndUpdateParams,
    api,
    environment,
    params,
  );

  let meta: ApiV2Meta | undefined = yield select((state) =>
    selectV2ApiMetaById(state, apiId),
  );
  while (meta?.dirty || meta?.saving) {
    yield take((action: Action) => {
      if (action.type === persistV2ApiSaga.success.type) {
        const persistSuccessAction = action as PayloadActionWithMeta<
          ApiDtoWithPb,
          SagaActionMeta<PersistApiPayload>
        >;
        return getV2ApiId(persistSuccessAction.payload) === apiId;
      }

      return false;
    });
    meta = yield select((state) => selectV2ApiMetaById(state, apiId));
  }
  if (!api.apiPb?.metadata) {
    return;
  }

  let agents: Agent[] = [];
  if (isOnPremise) {
    agents = yield call(getActiveAgents, {
      organization,
      environment,
      enableProfiles,
      profile,
    });
  }

  yield call(
    authenticateFeDatasources,
    api,
    profileId,
    profile,
    enableProfiles ? profile.key : environment,
    mode,
    agents,
    organization,
  );

  yield callSagas([
    getV2ApiTestDataSaga.apply({
      apiId,
      blockName,
      computeDependencies: true,
      computeForHiddenForm: true,
    }),
  ]);

  const { variablesBlock, inputs, attachedFiles, errors } = yield call(
    getUserGeneratedInputs,
    {
      apiId,
      blockName,
    },
  );

  if (errors && errors.length) {
    throw new EvaluationError("Error evaluating inputs", errors);
  }

  const apiPb = api.apiPb;

  // block execution takes api definition instead of fetching from server
  // api blocks are fetched from state and might not be already marshalled
  marshalBlockInPlace(block);

  // The orchestrator relies on a `name` field instead of the `key` field
  const profileForExecuteSingleBlockRequest = profile
    ? { ...profile, name: profile.key }
    : undefined;

  const executionRequestPayload: BackendTypes.ApiV2ExecutionRequest = {
    options: {
      includeEventOutputs: includeOutputs,
      includeEvents: includeOutputs,
      includeResolved: includeOutputs,
    },
    inputs,
    profile: profileForExecuteSingleBlockRequest,
    definition: {
      api: {
        ...apiPb,
        blocks: variablesBlock
          ? [
              variablesBlock,
              processIntegrationConfig(
                block,
                (controlFlow.blocks[blockName]?.config as any)?.pluginId,
              ),
            ]
          : [
              processIntegrationConfig(
                block,
                (controlFlow.blocks[blockName]?.config as any)?.pluginId,
              ),
            ],
      },
    },
  };

  apiStepAbortMap.set(`${apiId}_${blockName}`, new AbortController());
  const useCloudOpa: boolean = yield select(selectFlagById, Flag.USE_CLOUD_OPA);
  const opaRequestPercentage: number = yield select(
    selectFlagById,
    Flag.OPA_REQUEST_PERCENTAGE,
  );

  let baseUrl: string;

  if (useCloudOpa && Math.random() * 100 < opaRequestPercentage) {
    baseUrl = SUPERBLOCKS_UI_CLOUD_OPA_BASE_URL;
  } else {
    baseUrl = SUPERBLOCKS_UI_AGENT_BASE_URL;
  }

  const result: Awaited<ReturnType<typeof executeV2Api>> = yield call(
    executeV2Api,
    {
      body: executionRequestPayload,
      api,
      environment,
      eventType,
      notifyOnSystemError,
      organization,
      responseType: ApiResponseType.STREAM,
      onMessage,
      processStreamEvents,
      controlFlowOnlyFiles: attachedFiles,
      abortController: apiStepAbortMap.get(`${apiId}_${blockName}`),
      baseUrl,
      agents,
    },
  );

  const endResult:
    | BackendTypes.ApiV2ExecutionResponse
    | {
        systemError: string;
      }
    | undefined = parseFinalResult(result);

  yield callSagas([
    computeEnrichedExecutionSaga.apply({
      executionResult: endResult,
      apiId,
      options: {
        mergeExecutionEvents: true,
        blocksToReinitialize: [blockName],
        sendErrorOnUnknownBlockErrors: true,
      },
    }),
  ]);

  return endResult;
}

export const executeV2ApiBlockSaga = createSaga(
  executeV2ApiBlockInternal,
  "executeV2ApiBlockSaga",
  {
    sliceName: slice.name,
    keySelector: (payload) => payload.apiId,
    cancelledBy: [stopEvaluation.type],
  },
);

slice.saga(executeV2ApiBlockSaga, {
  start(state, { payload }) {
    state.meta[payload.apiId] = state.meta[payload.apiId] ?? {};
    state.meta[payload.apiId].singleStepRuns = {
      ...state.meta[payload.apiId].singleStepRuns,
      [payload.blockName]: {
        loading: true,
        cancelled: false,
      },
    };
    delete state.meta[payload.apiId].editedSinceLastExecution;
    if (state.errors[payload.apiId]?.type === EntitiesErrorType.EXECUTE_ERROR) {
      delete state.errors[payload.apiId];
    }
  },
  success(state, { payload, meta }) {
    const apiId = meta.args.apiId;
    const blockName = meta.args.blockName;
    state.meta[apiId] = state.meta[apiId] ?? {};
    state.meta[apiId].executionResult =
      payload as unknown as BackendTypes.ApiV2ExecutionResponse;
    state.meta[apiId].waitingForEvaluationSince = new Date().toString();
    state.meta[apiId].singleStepRuns = {
      ...state.meta[apiId].singleStepRuns,
      [blockName]: {
        loading: false,
        cancelled: false,
        hasRunMostRecently: true,
      },
    };
  },
  error(state, { payload, meta }) {
    const apiId = meta.args.apiId;
    const blockName = meta.args.blockName;
    const apiMeta = state.meta[apiId];

    if ((payload as EvaluationError).evaluationErrors) {
      if (apiMeta) {
        const generalErrors: string[] = [];
        const errorsByField: Record<string, string> = {};
        (payload as EvaluationError).evaluationErrors.forEach((error) => {
          const typedError = error as {
            context?: { propertyPath?: string; binding: string };
            message: string;
          };
          if (!typedError?.context) {
            generalErrors.push(typedError.message);
          } else {
            const binding = typedError.context.binding;
            // look for the field that has the binding as its value
            const field = Object.keys(
              apiMeta.testDataForBlock?.[blockName] ?? {},
            ).find((key) => {
              const testData = apiMeta.testDataForBlock?.[blockName]?.[key];
              return testData?.value === binding;
            });
            if (field) {
              errorsByField[field] = typedError.message;
            } else {
              generalErrors.push(typedError.message);
            }
          }
        });
        // show a ui notification for general errors
        sendErrorUINotification({
          message: `Could not execute block: Failed to evaluate inputs.`,
        });
        apiMeta.testDataEvaluationError = {
          ...apiMeta.testDataEvaluationError,
          [blockName]: {
            fieldErrors: errorsByField,
            generalErrors,
          },
        };
      }
    } else {
      state.errors[apiId] = {
        error: payload,
        type: EntitiesErrorType.EXECUTE_ERROR,
      };
    }

    if (apiMeta) {
      apiMeta.waitingForEvaluationSince = new Date().toString();
      apiMeta.singleStepRuns = {
        ...apiMeta.singleStepRuns,
        [blockName]: {
          loading: false,
          cancelled: false,
          hasRunMostRecently: true,
        },
      };
    }
  },
  cancel(state, { payload }) {
    apiStepAbortMap.get(`${payload.apiId}_${payload.blockName}`)?.abort();

    const apiMeta = state.meta[payload.apiId];
    if (apiMeta) {
      delete apiMeta.waitingForEvaluationSince;
      apiMeta.singleStepRuns = {
        ...apiMeta.singleStepRuns,
        [payload.blockName]: {
          loading: false,
          cancelled: true,
          hasRunMostRecently: true,
        },
      };
    }
  },
});
