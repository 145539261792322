import {
  Agent,
  ApiResponseType,
  ApplicationScope,
  Organization,
} from "@superblocksteam/shared";
import { uniq } from "lodash";
import { call, select, take } from "redux-saga/effects";
import {
  SUPERBLOCKS_UI_AGENT_BASE_URL,
  SUPERBLOCKS_UI_CLOUD_OPA_BASE_URL,
} from "env";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import { EventType } from "legacy/constants/ActionConstants";
import { evaluateActionBindings } from "legacy/sagas/EvaluationsShared";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { getActiveAgents } from "store/slices/apisShared/utils";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { EntitiesErrorType } from "store/utils/types";
import logger from "utils/logger";
import { Action, PayloadActionWithMeta } from "../../../utils/action";
import {
  callSagas,
  createSaga,
  forkSagas,
  SagaActionMeta,
} from "../../../utils/saga";
import { ApiExecutionSagaPayload, ApiTriggerType } from "../../apis/types";
import { selectOnlyOrganization } from "../../organizations";
import { orgIsOnPremise } from "../../organizations/utils";
import * as BackendTypes from "../backend-types";
import { executeV2Api } from "../client";
import {
  createExpandedBindingValueObject,
  extractAttachedFiles,
} from "../control-flow/bindings";
import {
  selectV2ApiById,
  selectV2ApiExtractedBindingsSingle,
  selectV2ApiMetaById,
} from "../selectors";
import slice, {
  CancelledByType,
  type ApiDtoWithPb,
  type ApiV2Meta,
} from "../slice";
import { ExecutionResponse } from "../types";
import { getTriggerTypeFromApi, translateViewMode } from "../utils/api-utils";
import { getV2ApiId } from "../utils/getApiIdAndName";
import { computeEnrichedExecutionSaga } from "./computeEnrichedExecution";
import {
  authenticateFeDatasources,
  getMessageHandlers,
  getProfileAndUpdateParams,
} from "./execute-utils";
import { getV2ApiToComponentDepsSaga } from "./getV2ApiToComponentDeps";
import { PersistApiPayload, persistV2ApiSaga } from "./persistV2Api";

function* checkApiMetaState(apiId: string) {
  let meta: ApiV2Meta | undefined = yield select((state) =>
    selectV2ApiMetaById(state, apiId),
  );
  while (meta?.dirty || meta?.saving) {
    yield take((action: Action) => {
      if (action.type === persistV2ApiSaga.success.type) {
        const persistSuccessAction = action as PayloadActionWithMeta<
          ApiDtoWithPb,
          SagaActionMeta<PersistApiPayload>
        >;
        return getV2ApiId(persistSuccessAction.payload) === apiId;
      }

      return false;
    });
    meta = yield select((state) => selectV2ApiMetaById(state, apiId));
  }
}

export function* getDynamicParamsAndFiles(
  apiId: string,
  triggerType: ApiTriggerType,
): Generator<
  unknown,
  {
    dynamicParams: Record<string, unknown>;
    attachedFiles: BackendTypes.FileRequestParam[];
  },
  any
> {
  let dynamicParams: Record<string, unknown> = {};
  let attachedFiles: BackendTypes.FileRequestParam[] = [];
  if (triggerType === ApiTriggerType.UI) {
    yield callSagas([
      getV2ApiToComponentDepsSaga.apply({ apiIdsToAnalyze: [apiId] }),
    ]);
    const unscopedBindings: ReturnType<
      typeof selectV2ApiExtractedBindingsSingle
    > = yield select(selectV2ApiExtractedBindingsSingle, apiId);

    let bindings = unscopedBindings.map((b) => b.str);

    // remove icons from bindings
    bindings = bindings.filter((b) => b !== "icons");
    // Add Global because it can be used within integrations
    // TODO: Should we know if this is needed or not?
    bindings.push("Global");
    // remove duplicates
    bindings = uniq(bindings);

    const values: unknown[] = yield call(
      evaluateActionBindings,
      bindings,
      ApplicationScope.PAGE, // TODO(API_SCOPE)
    );

    const rawBindingValues = Array(
      Math.max(unscopedBindings.length, values.length),
    )
      .fill(undefined)
      .reduce((accum: Record<string, unknown>, _, i) => {
        const bindingString = bindings[i];

        accum[bindingString] = values[i];
        return accum;
      }, {});
    attachedFiles = yield call(extractAttachedFiles, rawBindingValues);
    dynamicParams = createExpandedBindingValueObject(rawBindingValues);
  }

  return {
    dynamicParams,
    attachedFiles,
  };
}

export type Props = ApiExecutionSagaPayload & {
  includeOutputs: boolean;
};

/* This map is used to keep track of ongoing API requests and allows for the ability to abort them if needed. */
const apiAbortMap = new Map<string, AbortController>();

function* executeV2ApiInternal({
  apiId,
  environment,
  eventType,
  includeOutputs,
  params,
  notifyOnSystemError,
  commitId,
  callStack,
}: Props): Generator<unknown, undefined | ExecutionResponse, any> {
  // TODO: maybe we should also ship call stacks to the agent
  logger.debug("executeV2ApiInternal", {
    apiId,
    callStack: callStack.map((item) => item.propertyPath).reverse(),
  });

  const api: ReturnType<typeof selectV2ApiById> = yield select((state) =>
    selectV2ApiById(state, apiId),
  );

  if (!api) {
    console.error(`No api found for id: ${apiId}`);
    return;
  }
  if (!api.apiPb?.metadata) {
    console.error(`No metadata found for api: ${apiId}`);
    return;
  }

  yield call(checkApiMetaState, apiId);

  const organization: Organization = yield select(selectOnlyOrganization);
  const isOnPremise = orgIsOnPremise(organization);

  const { profile, mode, profileId } = yield call(
    getProfileAndUpdateParams,
    api,
    environment,
    params,
  );

  const enableProfiles: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES,
  );

  let agents: Agent[] = [];
  if (isOnPremise) {
    agents = yield call(getActiveAgents, {
      organization,
      environment,
      enableProfiles,
      profile,
    });
  }

  const isManualRun = eventType === EventType.ON_RUN_CLICK;
  const { onMessage, processStreamEvents, responseType, parseFinalResult } =
    yield call(
      getMessageHandlers,
      apiId,
      includeOutputs,
      undefined,
      undefined,
      isManualRun,
    );

  yield call(
    authenticateFeDatasources,
    api,
    profileId,
    profile,
    enableProfiles ? profile?.key : environment,
    mode,
    agents,
    organization,
  );

  const triggerType = getTriggerTypeFromApi(api.apiPb);
  const { dynamicParams, attachedFiles } = yield call(
    getDynamicParamsAndFiles,
    getV2ApiId(api),
    triggerType,
  );

  const currentBranch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );

  // We want to include events for all streaming APIs, so that the UI can detect and handle
  // non-5xx execution errors (i.e. user/integration errors)
  // We also want to include events for all that explicitly request outputs to be included
  const includeEvents =
    responseType === ApiResponseType.STREAM || includeOutputs;

  const executionRequestPayload: BackendTypes.ApiV2ExecutionRequest = {
    options: {
      includeEventOutputs: includeOutputs,
      includeEvents: includeEvents,
      includeResolved: includeOutputs,
    },
    inputs: dynamicParams,
    fetch: {
      id: apiId,
      profile: {
        name: profile?.key,
        id: profileId ? profileId : undefined, // dont specify if empty string
      },
      viewMode: translateViewMode(mode),
      commitId,
      branchName: currentBranch?.name,
    },
  };

  // Kickoff a saga to set the current execution result to nothing while we run the code
  const blankExecutionResult: BackendTypes.ApiV2ExecutionResponse = {
    execution: "unknown",
    status: "STATUS_EXECUTING",
    events: [],
    errors: [],
  };
  yield forkSagas([
    computeEnrichedExecutionSaga.apply({
      executionResult: blankExecutionResult,
      apiId,
    }),
  ]);

  // We ALWAYS use stream in edit mode to get the events in the UI
  // We use sync in run mode as an optimization to avoid the overhead of streaming if we don't need it
  const executeResponseType = includeOutputs
    ? ApiResponseType.STREAM
    : responseType;

  apiAbortMap.set(apiId, new AbortController());

  const useCloudOpa: boolean = yield select(selectFlagById, Flag.USE_CLOUD_OPA);
  const opaRequestPercentage: number = yield select(
    selectFlagById,
    Flag.OPA_REQUEST_PERCENTAGE,
  );

  let baseUrl: string;

  if (useCloudOpa && Math.random() * 100 < opaRequestPercentage) {
    baseUrl = SUPERBLOCKS_UI_CLOUD_OPA_BASE_URL;
  } else {
    baseUrl = SUPERBLOCKS_UI_AGENT_BASE_URL;
  }

  const result: Awaited<ReturnType<typeof executeV2Api>> = yield call(
    executeV2Api,
    {
      body: executionRequestPayload,
      api,
      environment,
      eventType,
      notifyOnSystemError,
      organization,
      responseType: executeResponseType,
      onMessage,
      processStreamEvents,
      controlFlowOnlyFiles: attachedFiles,
      abortController: apiAbortMap.get(apiId),
      baseUrl,
      agents,
    },
  );

  const endResult = parseFinalResult(result);

  yield callSagas([
    computeEnrichedExecutionSaga.apply({
      executionResult: endResult,
      apiId,
    }),
  ]);

  return endResult;
}

export const executeV2ApiSaga = createSaga(
  executeV2ApiInternal,
  "executeV2ApiSaga",
  {
    sliceName: slice.name,
    keySelector: (payload) => payload.apiId,
    cancelledBy: [stopEvaluation.type, restartEvaluation.type],
  },
);

slice.saga(executeV2ApiSaga, {
  start(state, { payload }) {
    state.meta[payload.apiId] = state.meta[payload.apiId] ?? {};
    state.meta[payload.apiId].cancelled = false;
    const startingRuns = state.meta[payload.apiId].concurrentRuns ?? 0;
    state.meta[payload.apiId].concurrentRuns = startingRuns + 1;
    state.loading[payload.apiId] = true;
    delete state.meta[payload.apiId].editedSinceLastExecution;
    if (state.errors[payload.apiId]?.type === EntitiesErrorType.EXECUTE_ERROR) {
      delete state.errors[payload.apiId];
    }
    delete state.meta[payload.apiId].cancelledByType;
    // clear out the single step run state
    delete state.meta[payload.apiId].singleStepRuns;
  },
  success(state, { payload, meta }) {
    // check the api abort map to see if it was cancelled
    if (apiAbortMap.get(meta.args.apiId)?.signal?.aborted) {
      // handle in same way as a cancel
      const apiMeta = state.meta[meta.args.apiId];
      apiMeta.cancelled = true;
      apiMeta.concurrentRuns = 0;
      if (
        payload &&
        "cancelledByType" in payload &&
        payload.cancelledByType === CancelledByType.MANUAL
      ) {
        apiMeta.cancelledByType = CancelledByType.MANUAL;
      }
      delete apiMeta.waitingForEvaluationSince;

      delete state.loading[meta.args.apiId];
      return;
    }
    state.meta[meta.args.apiId] = state.meta[meta.args.apiId] ?? {};
    state.meta[meta.args.apiId].executionResult =
      payload as unknown as BackendTypes.ApiV2ExecutionResponse;
    const startingRuns = state.meta[meta.args.apiId].concurrentRuns ?? 0;
    state.meta[meta.args.apiId].concurrentRuns = startingRuns - 1;
    state.meta[meta.args.apiId].waitingForEvaluationSince =
      new Date().toString();
    delete state.loading[meta.args.apiId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiId] = {
      error: payload,
      type: EntitiesErrorType.EXECUTE_ERROR,
    };
    const apiMeta = state.meta[meta.args.apiId];
    const startingRuns = apiMeta?.concurrentRuns ?? 0;
    if (apiMeta) {
      apiMeta.concurrentRuns = startingRuns - 1;
      apiMeta.waitingForEvaluationSince = new Date().toString();
    }
    delete state.loading[meta.args.apiId];
  },
  cancel(state, { payload }) {
    apiAbortMap.get(payload.apiId)?.abort();

    const apiMeta = state.meta[payload.apiId];
    if (apiMeta) {
      apiMeta.cancelled = true;
      apiMeta.concurrentRuns = 0;
      if (
        "cancelledByType" in payload &&
        payload.cancelledByType === CancelledByType.MANUAL
      ) {
        apiMeta.cancelledByType = CancelledByType.MANUAL;
      }
      delete apiMeta.waitingForEvaluationSince;
    }
    delete state.loading[payload.apiId];
  },
});
