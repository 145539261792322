import {
  AssignmentDto,
  AssignmentTypeEnum,
  PrincipalTypeEnum,
  ResourceTypeEnum,
  RoleDto,
  RoleSettingsDto,
  RoleTypeEnum,
} from "@superblocksteam/shared";
import { reduxApiSlice, RoleTag } from "./index";

export type AssignmentCreateRequestType = {
  principalId: string;
  principalType: PrincipalTypeEnum;
  assignmentId: string;
  assignmentType: AssignmentTypeEnum;
  resourceType: ResourceTypeEnum;
  resourceId: string;
  organizationId: string;
};

const extendedApi = reduxApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listRoles: builder.query<
      RoleDto[] | undefined,
      { type?: RoleTypeEnum; organizationId: string }
    >({
      query: ({ type, organizationId }) => {
        const queryString = new URLSearchParams();
        if (type) {
          queryString.append("type", type);
        }

        return `v1/organizations/${organizationId}/roles?${queryString.toString()}`;
      },
      transformResponse: (response: { data: RoleDto[] }) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        arg.type ? [RoleTag, { type: RoleTag, roleType: arg.type }] : [RoleTag],
    }),

    createRole: builder.mutation<
      RoleDto,
      { role: Omit<RoleDto, "id">; organizationId: string }
    >({
      query: ({ role, organizationId }) => ({
        url: `v1/organizations/${organizationId}/roles`,
        method: "POST",
        body: role,
      }),
      invalidatesTags: (result, err, arg) => [
        RoleTag,
        { type: RoleTag, roleType: arg.role.type },
      ],
    }),

    updateRoles: builder.mutation<
      RoleDto[],
      { roles: Partial<RoleDto>[]; organizationId: string }
    >({
      query: ({ roles, organizationId }) => ({
        url: `v1/organizations/${organizationId}/roles/update`,
        method: "POST",
        body: roles,
      }),
      invalidatesTags: (result, err, arg) => [
        RoleTag,
        { type: RoleTag, roleType: arg.roles[0].type },
      ],
    }),

    deleteRole: builder.mutation<
      RoleDto,
      {
        role: Partial<RoleDto>;
        organizationId: string;
        replacementRoleId: string;
      }
    >({
      query: ({ role, organizationId, replacementRoleId }) => ({
        url: `v1/organizations/${organizationId}/roles/${role.id}`,
        method: "DELETE",
        body: {
          replacementRoleId,
        },
      }),
      invalidatesTags: (result, err, arg) => [
        RoleTag,
        { type: RoleTag, roleType: arg.role.type },
      ],
    }),

    /* User / group assignments */
    updateOrganizationRole: builder.mutation<
      { data: AssignmentDto },
      {
        principalId: string;
        role: string | null;
        principalType: "group" | "user";
        organizationId: string;
      }
    >({
      query: ({ principalId, role, principalType, organizationId }) => ({
        url: `v1/organizations/${organizationId}/${principalType}s/${principalId}/role`,
        method: "PUT",
        body: { assignmentId: role },
      }),
    }),

    deleteOrganizationRole: builder.mutation<
      void,
      {
        principalId: string;
        role: string | null;
        principalType: "group" | "user";
        organizationId: string;
      }
    >({
      query: ({ principalId, role, principalType, organizationId }) => ({
        url: `v1/organizations/${organizationId}/${principalType}s/${principalId}/role/${role}`,
        method: "DELETE",
      }),
    }),

    getRoleSettings: builder.query<RoleSettingsDto, void>({
      query: () => `v2/rbac/role-settings`,
      transformResponse: (response: { data: RoleSettingsDto }) => {
        return response.data;
      },
    }),

    updateRoleSettings: builder.mutation<
      { data: RoleSettingsDto },
      RoleSettingsDto
    >({
      query: (data) => ({
        url: `v2/rbac/role-settings`,
        method: "PATCH",
        body: data,
      }),
    }),

    listUserAssignments: builder.query<
      AssignmentDto[],
      { userId: string; organizationId: string }
    >({
      query: ({ userId, organizationId }) =>
        ` v1/organizations/${organizationId}/users/${userId}/assignments`,
      transformResponse: (response: { data: AssignmentDto[] }) => {
        return response.data;
      },
    }),

    listGroupAssignments: builder.query<
      AssignmentDto[],
      { groupId: string; organizationId: string }
    >({
      query: ({ groupId, organizationId }) =>
        ` v1/organizations/${organizationId}/groups/${groupId}/assignments`,
      transformResponse: (response: { data: AssignmentDto[] }) => {
        return response.data;
      },
    }),

    /* Resource specific assignments */
    listResourceAssignments: builder.query<
      AssignmentDto[],
      { resourceType: ResourceTypeEnum; resourceId: string }
    >({
      query: ({ resourceType, resourceId }) =>
        `v2/rbac/assignments?${new URLSearchParams({
          resourceType,
          resourceId,
        }).toString()}`,
      transformResponse: (response: { data: AssignmentDto[] }) => {
        return response.data;
      },
    }),

    addResourceRoleAssignments: builder.mutation<
      AssignmentDto[],
      {
        assignments: AssignmentCreateRequestType[];
      }
    >({
      query: ({ assignments }) => ({
        url: `v2/rbac/assignments`,
        method: "POST",
        body: {
          assignments,
        },
      }),
      transformResponse: (response: { data: AssignmentDto[] }) => {
        return response.data;
      },
    }),

    updateResourceAssignment: builder.mutation<
      AssignmentDto,
      {
        assignmentId: string;
        assignmentType: AssignmentTypeEnum;
        assignmentPkId: string;
      }
    >({
      query: ({ assignmentId, assignmentType, assignmentPkId }) => ({
        url: `v2/rbac/assignments/${assignmentPkId}`,
        method: "PUT",
        body: {
          assignmentId,
          assignmentType,
        },
      }),
      transformResponse: (response: { data: AssignmentDto }) => {
        return response.data;
      },
    }),

    deleteResourceAssignment: builder.mutation<
      AssignmentDto,
      {
        assignmentPkId: string;
      }
    >({
      query: ({ assignmentPkId }) => ({
        url: `v2/rbac/assignments/${assignmentPkId}`,
        method: "DELETE",
      }),
      transformResponse: (response: { data: AssignmentDto }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useListRolesQuery,
  useCreateRoleMutation,
  useUpdateRolesMutation,
  useDeleteRoleMutation,
  useUpdateOrganizationRoleMutation,
  useDeleteOrganizationRoleMutation,
  useGetRoleSettingsQuery,
  useUpdateRoleSettingsMutation,
  // useListGroupAssignmentsQuery,
  // useListUserAssignmentsQuery,
  useListResourceAssignmentsQuery,
  useLazyListResourceAssignmentsQuery,
  useAddResourceRoleAssignmentsMutation,
  useUpdateResourceAssignmentMutation,
  useDeleteResourceAssignmentMutation,
} = extendedApi;
