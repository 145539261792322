import equal from "@superblocksteam/fast-deep-equal";
import { PageDSL8 } from "@superblocksteam/shared";
import { select } from "redux-saga/effects";
import {
  PageLoadAction,
  SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE,
} from "legacy/appMigrations/migrations/v5to6";
import { selectAllV2Apis } from "store/slices/apisV2";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import { Flags, selectFlags } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { UIEvent } from "utils/event";
import logger from "utils/logger";
import { AuxiliaryData, migratePage } from "../../appMigrations/MigratePage";

export function* migrateServerDSL(
  serverDSL: PageDSL8,
): Generator<any, PageDSL8, any> {
  const flags: ReturnType<typeof selectFlags> = yield select(selectFlags);
  const extraData: AuxiliaryData = {
    apis: (yield select(selectAllV2Apis)) as ReturnType<typeof selectAllV2Apis>,
  };

  if (!extraData.apis) {
    logger.error("Extra migration data missing, apis");
  }

  const migratedDSL = migratePage({
    serverDSL,
    flags,
    extraData,
  });

  DryRunManager.performDryRunMigration(migratedDSL, flags, extraData);

  return migratedDSL;
}

// TODO: @sachdevs @mrhether to remove this in a follow up PR
class DryRunManager {
  private static dryRunVersion = 6;
  private static dryRunDSL: PageDSL8 | undefined;

  private constructor() {}

  static performDryRunMigration(
    migratedDSL: PageDSL8,
    flags: Flags,
    extraData: AuxiliaryData,
  ) {
    try {
      if (
        migratedDSL.layoutVersion &&
        migratedDSL.layoutVersion >= this.dryRunVersion
      ) {
        return;
      }

      this.dryRunDSL = migratePage({
        serverDSL: fastClone(migratedDSL),
        currentVersion: this.dryRunVersion,
        flags,
        extraData,
      });
    } catch (e) {
      logger.warn({
        message: "Failed to dry run DSL migration",
        toVersion: this.dryRunVersion,
        fromVersion: migratedDSL.layoutVersion,
        error: e instanceof Error ? e.message : "Unknown error",
      });
    }
  }

  static compareToDryRun(
    loadApis: ApiDtoWithPb[],
  ): "equal" | "not_equal" | "error" | "no_dry_run" {
    if (!this.dryRunDSL) {
      return "no_dry_run";
    }

    try {
      const pageLoadAction = this.dryRunDSL.onPageLoad?.find(
        (action) =>
          (action as PageLoadAction)?.type ===
          SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE,
      ) as PageLoadAction | undefined;
      const isMigrated = !!pageLoadAction;

      const migratedApis = isMigrated ? pageLoadAction?.apiNames.sort() : [];
      const computedApis = loadApis.map((api) => getV2ApiName(api)).sort();

      if (!equal(migratedApis, computedApis)) {
        logger.event(UIEvent.DRY_RUN_MISMATCH, {
          migratedApis,
          computedApis,
          cachedData: this.dryRunDSL.cachedData,
        });
        return "not_equal";
      }
      return "equal";
    } catch (error) {
      logger.warn({
        message: "Error comparing dry run and computed APIs",
        error: error instanceof Error ? error.message : "Unknown error",
      });
      return "error";
    }
  }
}

interface DryRunManagerInterface {
  compareToDryRun: typeof DryRunManager.compareToDryRun;
}
export const DryRunMigrationManger: DryRunManagerInterface = DryRunManager;
