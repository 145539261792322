import {
  ApplicationUserStatus,
  AssignmentDto,
  AssignmentTypeEnum,
  DefaultGroupName,
  GroupBrief,
  GroupType,
  OrganizationUserDto,
  OrganizationUserStatus,
  PrincipalTypeEnum,
  ResourceTypeEnum,
  RoleDto,
  RoleTypeEnum,
} from "@superblocksteam/shared";
import { Switch, Tabs } from "antd";
import Modal from "antd/lib/modal";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CodeIcon } from "assets/icons/common/code-bordered.svg";
import { ReactComponent as GroupIcon } from "assets/icons/common/users.svg";

import CopyLink from "components/ui/CopyLink";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { Spinner } from "components/ui/Spinner";
import { VIEW_ROLES } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import { scrollbarLightCss } from "legacy/constants/DefaultTheme";
import { getApplicationDeployedURL } from "legacy/constants/routes";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import {
  Invitee,
  InviteeToSend,
  SHARE_MODAL_ROLE_SELECT_WIDTH,
  UserInvitee,
} from "pages/Permissions/constants";
import { GROUPS_FOR_ORG_ID_URL, USERS_FOR_ORG_ID_URL } from "pages/routes";
import { useAppDispatch } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import {
  AssignmentCreateRequestType,
  useAddResourceRoleAssignmentsMutation,
  useDeleteResourceAssignmentMutation,
  useLazyListResourceAssignmentsQuery,
  useListRolesQuery,
  useUpdateResourceAssignmentMutation,
} from "store/slices/reduxApi/rbac";
import { callServer, HttpMethod } from "store/utils/client";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { updateApplicationMetadata } from "../../store/slices/application/applicationActions";
import { getCurrentApplication } from "../../store/slices/application/selectors";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "../../utils/notification";
import { StyledAvatarWrapper } from "./ColoredAvatar";
import PermissionEntriesListV2, {
  ItemRow,
  StyledNameDescription,
} from "./PermissionEntriesListV2";
import SearchAndInviteV2 from "./SearchAndInviteV2";

enum ShareTabKey {
  TEAM = "team",
  PUBLIC = "public",
}

const SHARE_MODAL_WIDTH = 520;

const StyledModal = styleAsClass`
  .ant-typography {
    display: block;
    margin-bottom: 12px;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-header {
    padding: 14px 12px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${colors.GREY_900};
    height: 48px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-start;
    gap: 36px;
    font-size: 12px;
    line-height: 16px;
    height: 48px;
    align-items: center;
  }

  .ant-modal-footer > div {
    width: auto;
  }

  .ant-tabs-tab {
    padding: 8px 10px 8px 10px;
  }
  .ant-tabs-nav {
    height: 48px;
    padding: 0px 12px;
    margin-bottom: 0px !important;
  }
  .ant-tabs-tab-btn {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.GREY_500};
  }
  .ant-tabs-content {
    padding: 16px;
    min-height: 292px;
    padding-bottom: 8px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.GREY_900};
    font-weight: 500;
  }
  .ant-modal-close-x {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 13px;
  }
`;

const PermissionListWrapper = styleAsClass`
  margin-top: 8px;
  padding-top: 2px;
  min-height: 194px;
  max-height: 194px;
  overflow: auto;
  ${scrollbarLightCss}
`;

const FooterLinkWrapper = styleAsClass`
  color: ${colors.ACCENT_BLUE_600};
  display: flex;
  align-items: center;
  gap: 3px;
  &:hover {
    cursor: pointer;
    color: ${colors.ACCENT_BLUE_700};
  }
  path {
    stroke: ${colors.ACCENT_BLUE_600};
  }
`;
const PublicLinkConfig = styleAsClass`
  border: 1px solid ${colors.GREY_100};
  border-radius: 4px;
  padding: 8px;
  gap: 8px;
  display: flex;
  flex-direction: row;
`;

const PublicLinkDescription = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 16px;
  font-size: 12px;
  div:first-child {
    font-weight: 500;
    color: ${colors.GREY_700};
  }
  div:last-child  {
    color: ${colors.GREY_500};
  }
`;

const RoleSelectWrapper = styleAsClass`
  display: flex;
  align-items: center;
  input {
    border: 1px solid transparent !important;
    font-weight: 500;
  }
  input:focus, input:hover{
    border: 1px solid ${colors.ACCENT_BLUE_500} !important;  
  }
  .bp5-input-group::before {
    svg {
      fill: ${colors.GREY_300};
    }
  }
`;

interface Props {
  isVisible: boolean;
  organizationId: string;
  setShareModalVisible: (visible: boolean) => void;
  resourceId: string;
  resourceType: ResourceTypeEnum;
  resourceDisplayName: string;
  onModalClose?: (assignments: AssignmentDto[]) => void;
  roleType: RoleTypeEnum;
  inEditMode: boolean;
  showShareTab?: boolean;
  showPublicTab?: boolean;
}

const ShareModal = ({
  isVisible,
  resourceId,
  organizationId,
  setShareModalVisible,
  roleType,
  resourceType,
  resourceDisplayName,
  onModalClose,
  inEditMode,
  showPublicTab,
  showShareTab,
}: Props) => {
  const dispatch = useAppDispatch();
  const application = useSelector(getCurrentApplication);
  const organization = useSelector(selectOnlyOrganization);
  const [canViewRoles] = useAuthorizationCheck([VIEW_ROLES]);

  const { data: roles = [] } = useListRolesQuery(
    {
      type: roleType,
      organizationId,
    },
    {
      skip: !canViewRoles,
    },
  );

  const [isPublic, setPublic] = useState(false);

  useEffect(() => {
    (async () => {
      if (application && resourceType === ResourceTypeEnum.APPLICATIONS) {
        setPublic(!!application.isPublic);
      }
    })();
  }, [dispatch, resourceId, isVisible, application, resourceType]);

  // Autocomplete
  const [allUsersForOrg, setUsers] = useState<OrganizationUserDto[]>([]);
  const [allGroupsForOrg, setGroups] = useState<GroupBrief[]>([]);

  const updateApplicationDispatch = useCallback(
    (id: string, data: { isPublic: boolean }) => {
      dispatch(updateApplicationMetadata(id, data));
    },
    [dispatch],
  );

  const handleTogglePublic = useCallback(
    (newIsPublic: boolean) => {
      if (resourceId) {
        if (application) {
          updateApplicationDispatch(resourceId || "", {
            isPublic: newIsPublic,
          });
        }
        setPublic(newIsPublic);

        sendSuccessUINotification({
          message: "Access updated",
        });
      }
    },
    [application, resourceId, updateApplicationDispatch],
  );

  const loadAutocomplete = useCallback(async () => {
    function getUsersForOrgId(orgId: string) {
      return callServer<OrganizationUserDto[]>(
        {
          method: HttpMethod.Get,
          url: USERS_FOR_ORG_ID_URL(),
          params: { orgId },
        },
        {
          notifyOnError: false,
        },
      );
    }

    function getGroupsForOrg(orgId: string) {
      return callServer<{ groups: GroupBrief[] }>(
        {
          method: HttpMethod.Get,
          url: GROUPS_FOR_ORG_ID_URL(),
          params: { orgId },
        },
        {
          notifyOnError: false,
        },
      );
    }

    const retUsers = await getUsersForOrgId(organizationId);
    const retGroups = await getGroupsForOrg(organizationId);
    setUsers(retUsers);
    setGroups(retGroups.groups);
  }, [setUsers, organizationId]);

  // Share entries list
  const currentUser = useSelector(getCurrentUser);

  const [assignments, setAssignments] = useState<AssignmentDto[]>([]);
  const [isAssignmentsLoading, setIsAssignmentsLoading] = useState(false);

  const [
    trigger,
    {
      data: assignmentsFetched,
      isLoading: isAssignmentsLoadingInitially,
      isFetching: isAssignmentsRefetching,
    },
  ] = useLazyListResourceAssignmentsQuery();

  useEffect(() => {
    if (isVisible) {
      // reload users list on modal open
      loadAutocomplete();
      trigger({
        resourceType,
        resourceId,
      });
    }
  }, [isVisible, loadAutocomplete, resourceType, resourceId, trigger]);

  useEffect(() => {
    if (!isVisible) {
      trigger({
        resourceType,
        resourceId,
      });
    }
    // only call this when you close the modal, to make the redux state updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (assignmentsFetched) {
      setAssignments(assignmentsFetched ?? []);
    }
  }, [assignmentsFetched]);

  const allUsersGroup = allGroupsForOrg.find(
    (group) => group.type === GroupType.EVERYONE,
  );

  const allUsersGroupName = allUsersGroup?.name || DefaultGroupName.EVERYONE;

  const allUsersGroupAssignment = assignments.find(
    (assignment) =>
      assignment.principalType === "group" &&
      assignment?.group?.name === allUsersGroupName,
  );

  const [addResourceRoleAssignments] = useAddResourceRoleAssignmentsMutation();

  const upsertAssignments = useCallback(
    async ({
      role,
      invitees,
    }: {
      role?: RoleDto;
      invitees: InviteeToSend[];
    }) => {
      if (!role) {
        sendErrorUINotification({
          message: `No role is found`,
        });
        return;
      }
      const assignmentsToCreate: AssignmentCreateRequestType[] = invitees.map(
        (invitee) => ({
          assignmentId: role.id,
          assignmentType: AssignmentTypeEnum.ROLE,
          principalId: invitee.id,
          principalType: invitee.type as PrincipalTypeEnum,
          resourceType,
          resourceId,
          organizationId,
        }),
      );

      setIsAssignmentsLoading(true);
      try {
        const newAssignments = await addResourceRoleAssignments({
          assignments: assignmentsToCreate,
        }).unwrap();

        setAssignments((assignments) => [...assignments, ...newAssignments]);

        sendSuccessUINotification({
          message: "Access updated",
        });
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to add access ${error?.message}`,
        });
      } finally {
        setIsAssignmentsLoading(false);
      }
    },
    [addResourceRoleAssignments, organizationId, resourceId, resourceType],
  );

  const [updateAssignmentRoleAssignment] =
    useUpdateResourceAssignmentMutation();

  const updateAssignment = useCallback(
    async (assignment: AssignmentDto) => {
      setIsAssignmentsLoading(true);
      try {
        const updatedAssignment = await updateAssignmentRoleAssignment({
          assignmentPkId: assignment.id,
          assignmentId: assignment.assignmentId,
          assignmentType: AssignmentTypeEnum.ROLE,
        }).unwrap();

        setAssignments((assignments) => {
          return assignments.map((a) => {
            if (a.id === assignment.id) {
              return { ...a, role: updatedAssignment.role };
            }
            return a;
          });
        });

        sendSuccessUINotification({
          message: `Access updated`,
        });
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to update access. ${error?.message}`,
        });
      } finally {
        setIsAssignmentsLoading(false);
      }
    },
    [updateAssignmentRoleAssignment],
  );

  const [deleteResourceAssignment] = useDeleteResourceAssignmentMutation();
  const deleteAssignment = useCallback(
    async (assignmentToDelete: AssignmentDto) => {
      setIsAssignmentsLoading(true);
      try {
        await deleteResourceAssignment({
          assignmentPkId: assignmentToDelete.id,
        }).unwrap();

        setAssignments((assignments) => {
          const newAssignments = assignments.filter(
            (assignment) => assignment.id !== assignmentToDelete.id,
          );

          return newAssignments;
        });

        sendSuccessUINotification({
          message: `Access updated`,
        });
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to update access. ${error?.message}`,
        });
      } finally {
        setIsAssignmentsLoading(false);
      }
    },
    [deleteResourceAssignment],
  );

  const handleCancel = useCallback(() => {
    setShareModalVisible(false);
    onModalClose?.(assignments);
  }, [onModalClose, setShareModalVisible, assignments]);

  const userInvitees = useMemo(() => {
    return allUsersForOrg
      .filter((user) => user.status !== OrganizationUserStatus.INACTIVE)
      .map(
        (user) =>
          ({
            name: user.name,
            email: user.email,
            type: "user",
            id: user.id,
          } as Invitee),
      )
      .sort((a, b) =>
        (a as UserInvitee).email.localeCompare((b as UserInvitee).email),
      );
  }, [allUsersForOrg]);

  const groupInvitees = useMemo(() => {
    return allGroupsForOrg
      .filter((group) => group.type !== GroupType.ALL_USERS)
      .map(
        (group) =>
          ({
            name: group.name,
            type: "group",
            id: group.id,
            size: group.size,
          } as Invitee),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [allGroupsForOrg]);

  const sortedAssignments = useMemo(() => {
    const getSortPriority = (assignment: AssignmentDto) => {
      if (assignment.principalType === "user") {
        if (assignment.user?.email === currentUser?.email) {
          return 0;
        }
        if (
          assignment.user?.status === ApplicationUserStatus.EDITOR_PENDING_JOIN
        ) {
          return 2;
        }
      }
      return 1;
    };

    return assignments
      .filter(
        (assignment) =>
          !(
            assignment.principalType === "group" &&
            assignment.group?.name === allUsersGroupName
          ),
      )
      .sort((a, b) => {
        const aName = a.principalType === "user" ? a.user?.name : a.group?.name;
        const bName = b.principalType === "user" ? b.user?.name : b.group?.name;
        const diff = getSortPriority(a) - getSortPriority(b);
        if (diff < 0) {
          return -1;
        } else if (diff > 0) {
          return 1;
        }
        return (aName ?? "").localeCompare(bName ?? "");
      });
  }, [allUsersGroupName, assignments, currentUser?.email]);

  const allInvitees = useMemo(() => {
    const principalIdsAreadyAssigned = new Set(
      assignments.map(
        (assignment) => assignment.user?.id ?? assignment.group?.id,
      ),
    );
    return [...userInvitees, ...groupInvitees].filter(
      (invitee) => !principalIdsAreadyAssigned.has(invitee.id),
    );
  }, [assignments, groupInvitees, userInvitees]);

  const urlParams = new URLSearchParams(window.location.search);
  const accessRequestId = urlParams.get("access-request-id");
  //get an accessRequest id from url and use that to get the request info
  const handleAccessRequest = useCallback(async (accessRequestId: string) => {
    try {
      // TODO(rbac): handle the access request in new rbac
      // const { permissions, requester, role } = await acceptRequestAccess(
      //   accessRequestId,
      // );
      // const accessText = getAccessText(role);
      // setShareEntries(permissions ?? []);
      // sendSuccessUINotification({
      //   message: `${requester.name} ${accessText} ${resourceDisplayName} now`,
      // });
    } catch (err: any) {
      sendErrorUINotification({
        message: `Failed to grant access: ${err?.message}`,
      });
    }
  }, []);

  useEffect(() => {
    if (accessRequestId) {
      setShareModalVisible(true);
      handleAccessRequest(accessRequestId);
    }
  }, [accessRequestId, handleAccessRequest, setShareModalVisible]);

  const onClickSetupConfig = useCallback(() => {
    dispatch(
      updateApplicationSidebarKey({
        selectedKey: SideBarKeys.EMBEDDING,
      }),
    );
    setShareModalVisible(false);
  }, [dispatch, setShareModalVisible]);

  const enableEmbed = useFeatureFlag(Flag.ENABLE_EMBED);

  const parentRef = useRef<HTMLDivElement>(null);

  const roleOptions = useMemo(
    () =>
      roles.map((role, index) => {
        return {
          key: role.name,
          value: role.name,
          displayName: role.name,
          hasDivider: index === roles.length - 1,
        };
      }),
    [roles],
  );

  const roleOptionsForOrg = useMemo(
    () => [
      ...roleOptions,
      {
        key: "remove",
        value: "remove",
        displayName: "No access",
      },
    ],
    [roleOptions],
  );

  const onOrgResourceRoleChange = useCallback(
    (option: DropdownOption) => {
      if (!allUsersGroupAssignment && allUsersGroup?.id) {
        // does not exist, create one assignment with org
        upsertAssignments({
          invitees: [
            {
              name: allUsersGroupName,
              type: "group",
              id: allUsersGroup?.id,
            },
          ],
          role: roles.find((role) => role.name === option.value)!,
        });
      } else if (allUsersGroupAssignment) {
        if (option.value === "remove") {
          deleteAssignment(allUsersGroupAssignment);
        } else {
          const role = roles.find(
            (resourceRole) => resourceRole.name === option.value,
          );
          role &&
            updateAssignment({
              ...allUsersGroupAssignment,
              assignmentId: role.id,
              role,
            });
        }
      }
    },
    [
      allUsersGroup?.id,
      allUsersGroupAssignment,
      allUsersGroupName,
      deleteAssignment,
      roles,
      updateAssignment,
      upsertAssignments,
    ],
  );

  const [activeTabKey, setActiveTabKey] = useState<string>(ShareTabKey.TEAM);
  const tabOnChange = useCallback((key: string) => {
    setActiveTabKey(key);
  }, []);

  const onUpdateRole = useCallback(
    (assignment: AssignmentDto, roleName: string) => {
      const role = roles.find((resourceRole) => resourceRole.name === roleName);
      if (role) {
        updateAssignment({
          ...assignment,
          assignmentId: role.id,
          role,
        });
      }
    },
    [roles, updateAssignment],
  );

  const shareWithTeamContent = useMemo(
    () => (
      <>
        <SearchAndInviteV2
          allInvitees={allInvitees}
          onAddInvitees={upsertAssignments}
          roles={roles}
          parentRef={parentRef}
        />
        <div
          style={{
            fontSize: 13,
            lineHeight: "16px",
            fontWeight: 600,
            marginTop: 14,
          }}
        >
          Who has access
        </div>
        <Spinner
          spinning={
            isAssignmentsLoading ||
            isAssignmentsLoadingInitially ||
            isAssignmentsRefetching
          }
        >
          <div className={PermissionListWrapper}>
            <div className={ItemRow}>
              <div className={StyledAvatarWrapper}>
                <GroupIcon width="16" />
              </div>
              <div className={StyledNameDescription}>
                <div> {`Everyone at ${organization.name}`}</div>
                <div> {`${allUsersGroup?.size} members`}</div>
              </div>
              <div className={RoleSelectWrapper}>
                <RecommendedSingleDropdown
                  value={allUsersGroupAssignment?.role?.name ?? "remove"}
                  onChange={onOrgResourceRoleChange}
                  options={roleOptionsForOrg}
                  parentRef={parentRef}
                  width={SHARE_MODAL_ROLE_SELECT_WIDTH}
                  disableSearch={true}
                />
              </div>
            </div>

            {sortedAssignments.length > 0 && (
              <PermissionEntriesListV2
                parentRef={parentRef}
                currentUser={currentUser}
                assignments={sortedAssignments}
                roles={roles}
                updateRole={onUpdateRole}
                onRemoveEntry={deleteAssignment}
              />
            )}
          </div>
        </Spinner>
      </>
    ),
    [
      allInvitees,
      allUsersGroup?.size,
      allUsersGroupAssignment?.role?.name,
      currentUser,
      deleteAssignment,
      isAssignmentsLoading,
      isAssignmentsLoadingInitially,
      isAssignmentsRefetching,
      onOrgResourceRoleChange,
      onUpdateRole,
      organization.name,
      roleOptionsForOrg,
      roles,
      sortedAssignments,
      upsertAssignments,
    ],
  );

  const sharePublicContent = useMemo(() => {
    return (
      <div className={PublicLinkConfig}>
        <Switch checked={isPublic} onChange={handleTogglePublic} size="small" />
        <div className={PublicLinkDescription}>
          <div>{`Enable public link (read-only)`}</div>
          <div>
            Anyone on the web will be able to access your deployed app, even if
            they are not a part of your team.
          </div>
        </div>
      </div>
    );
  }, [handleTogglePublic, isPublic]);

  const tabs = useMemo(() => {
    let tabs: {
      key: ShareTabKey;
      title: string;
      contents: JSX.Element;
    }[] = [];
    if (resourceType === ResourceTypeEnum.APPLICATIONS) {
      if (showShareTab)
        tabs.push({
          key: ShareTabKey.TEAM,
          title: "Share with team",
          contents: shareWithTeamContent,
        });
      if (showPublicTab)
        tabs.push({
          key: ShareTabKey.PUBLIC,
          title: "Share publicly",
          contents: sharePublicContent,
        });
    } else {
      tabs = [
        {
          key: ShareTabKey.TEAM,
          title: "Share with team",
          contents: shareWithTeamContent,
        },
      ];
    }
    return tabs;
  }, [
    resourceType,
    showPublicTab,
    showShareTab,
    sharePublicContent,
    shareWithTeamContent,
  ]);

  const showTabs = tabs.length > 1;
  return (
    <Modal
      wrapClassName={StyledModal}
      bodyStyle={showTabs ? undefined : { padding: 16 }}
      open={isVisible}
      destroyOnClose
      data-test={`share-modal-${isVisible ? "visible" : "hidden"}`}
      onCancel={handleCancel}
      title={showTabs ? null : `Share ${resourceDisplayName}`}
      centered
      width={SHARE_MODAL_WIDTH}
      footer={
        resourceType === ResourceTypeEnum.APPLICATIONS && [
          <CopyLink
            key="share-link"
            url={window.location.origin + getApplicationDeployedURL(resourceId)}
            text="Copy Link"
          />,
          ...(enableEmbed && inEditMode
            ? [
                <div
                  key="embed-opener"
                  className={FooterLinkWrapper}
                  onClick={onClickSetupConfig}
                >
                  <CodeIcon /> Setup embed
                </div>,
              ]
            : []),
        ]
      }
    >
      <div ref={parentRef}>
        {showTabs ? (
          <Tabs
            defaultActiveKey={ShareTabKey.TEAM}
            onChange={tabOnChange}
            activeKey={activeTabKey}
          >
            {tabs.map((tab) => (
              <Tabs.TabPane tab={tab.title} key={tab.key}>
                {tab.contents}
              </Tabs.TabPane>
            ))}
          </Tabs>
        ) : (
          tabs[0]?.contents
        )}
      </div>
    </Modal>
  );
};

export default ShareModal;
