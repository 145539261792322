export type UI_BLOCKS_FOR_USERS_FLAG_TYPE = "off" | "demo" | "rc" | undefined;

const isUiBlocksDemo = (featureFlag: UI_BLOCKS_FOR_USERS_FLAG_TYPE) => {
  return featureFlag === "demo";
};
const isUiBlocksRc = (featureFlag: UI_BLOCKS_FOR_USERS_FLAG_TYPE) => {
  return featureFlag === "rc";
};
export const isUiBlocksForUsersFlagEnabled = (
  featureFlag: UI_BLOCKS_FOR_USERS_FLAG_TYPE,
) => {
  return isUiBlocksDemo(featureFlag) || isUiBlocksRc(featureFlag);
};
