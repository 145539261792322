import {
  ResourceSettingType,
  RoleSettingsDto,
  RoleTypeEnum,
} from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { OWNER_ROLE_NAME, ROLE_TYPES } from "constants/rbac";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import {
  useGetRoleSettingsQuery,
  useListRolesQuery,
  useUpdateRoleSettingsMutation,
} from "store/slices/reduxApi/rbac";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { getRoleTypeName } from "./Shared";

type NonOrganizationRoleSettings = {
  [K in Exclude<
    keyof RoleSettingsDto,
    RoleTypeEnum.ORGANIZATION
  >]: RoleSettingsDto[K];
}[Exclude<keyof RoleSettingsDto, RoleTypeEnum.ORGANIZATION>];

const ResourceSettingsGrid = styleAsClass`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.GREY_700};
    > .title {
        display: flex;
        align-self: center;
        font-weight: 500;
    }

    > .with-tooltip {
        width: max-content;
        border-bottom: 1px dashed ${colors.GREY_200};
    }
`;

const RESOURCES_WITH_CREATOR_ROLE = new Set([
  RoleTypeEnum.APPLICATIONS,
  RoleTypeEnum.WORKFLOWS,
  RoleTypeEnum.SCHEDULED_JOBS,
  RoleTypeEnum.INTEGRATIONS,
]);

export const RoleSettings = () => {
  const organizationId = useSelector(getCurrentOrgId);
  const { data: allRoles } = useListRolesQuery({
    organizationId,
  });
  const { data: roleSettings, refetch: refetchRoleSettings } =
    useGetRoleSettingsQuery();
  const [updateRoleSettings, { isLoading: isUpdatingRoles }] =
    useUpdateRoleSettingsMutation();

  const roleOptionsByType: Partial<Record<RoleTypeEnum, DropdownOption[]>> =
    useMemo(() => {
      if (!allRoles) return {};
      const roleOptionts = allRoles
        .map((role) => ({
          key: role.id,
          displayName: role.name,
          value: role.id,
          subText: role.description,
          roleType: role.type,
        }))
        .filter((role) => role.displayName !== OWNER_ROLE_NAME);
      return roleOptionts.reduce((acc, role) => {
        if (!acc[role.roleType]) {
          acc[role.roleType] = [];
        }
        acc[role.roleType].push(role);
        return acc;
      }, {} as Record<RoleTypeEnum, DropdownOption[]>);
    }, [allRoles]);

  const handleRoleChange = useCallback(
    async (
      roleType: RoleTypeEnum,
      level: ResourceSettingType,
      option?: DropdownOption,
    ) => {
      const response = await updateRoleSettings({
        [roleType]: {
          [level]: option?.value ?? null,
        },
      });
      if (response.error) {
        sendErrorUINotification({
          message: "Failed to update role settings",
        });
      } else {
        sendSuccessUINotification({
          message: "Role settings updated",
        });
        await refetchRoleSettings();
      }
    },
    [updateRoleSettings, refetchRoleSettings],
  );

  return (
    <div style={{ maxWidth: 600 }} className={FormWrapper}>
      <FormItem
        label="New user default role"
        style={{
          marginBottom: 4,
        }}
        subText="The organization role users will be assigned when joining your organization if not explicitly set."
      >
        <RecommendedSingleDropdown
          options={roleOptionsByType[RoleTypeEnum.ORGANIZATION] ?? []}
          value={
            roleSettings?.[RoleTypeEnum.ORGANIZATION]?.new_user ?? undefined
          }
          onChange={(option) =>
            handleRoleChange(
              RoleTypeEnum.ORGANIZATION,
              ResourceSettingType.NEW_USER,
              option,
            )
          }
          allowClearing={false}
          placeholder="No role"
          popoverProps={{
            matchTargetWidth: false,
          }}
          minDropdownWidth={{
            width: 340,
            atLeastTargetWidth: true,
          }}
          disabled={isUpdatingRoles}
        />
      </FormItem>
      <div>
        <div style={{ fontSize: 15, fontWeight: 700, marginBottom: 24 }}>
          Resource access
        </div>
        <div className={ResourceSettingsGrid}>
          <div className="title">Resource</div>
          <Tooltip title="Role assigned to all org members when a new resource is created.">
            <div className={"title with-tooltip"}>Base role</div>
          </Tooltip>
          <Tooltip title="Role assigned to the user who created a resource of this type.">
            <div className={"title with-tooltip"}>Resource creator role</div>
          </Tooltip>
          {ROLE_TYPES.map((roleType) => (
            <React.Fragment key={roleType}>
              <div className="title">{getRoleTypeName(roleType)}</div>
              <div>
                <RecommendedSingleDropdown
                  options={roleOptionsByType[roleType] ?? []}
                  value={
                    (roleSettings?.[roleType] as NonOrganizationRoleSettings)
                      ?.base ?? undefined
                  }
                  onChange={(option) =>
                    handleRoleChange(roleType, ResourceSettingType.BASE, option)
                  }
                  allowClearing={true}
                  placeholder="No role"
                  popoverProps={{
                    matchTargetWidth: false,
                  }}
                  minDropdownWidth={{
                    width: 340,
                    atLeastTargetWidth: true,
                  }}
                  disabled={isUpdatingRoles}
                />
              </div>
              <div>
                {RESOURCES_WITH_CREATOR_ROLE.has(roleType) && (
                  <RecommendedSingleDropdown
                    options={roleOptionsByType[roleType] ?? []}
                    value={
                      (roleSettings?.[roleType] as NonOrganizationRoleSettings)
                        ?.creator ?? undefined
                    }
                    onChange={(option) =>
                      handleRoleChange(
                        roleType,
                        ResourceSettingType.CREATOR,
                        option,
                      )
                    }
                    allowClearing={false}
                    placeholder="No role"
                    popoverProps={{
                      matchTargetWidth: false,
                    }}
                    minDropdownWidth={{
                      width: 340,
                      atLeastTargetWidth: true,
                    }}
                    disabled={isUpdatingRoles}
                  />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
