import equal from "@superblocksteam/fast-deep-equal";
import { Dimension } from "@superblocksteam/shared";
import { get } from "lodash";
import React from "react";
import { ReactComponent as ArrowDown } from "assets/icons/common/arrow-down.svg";
import { ReactComponent as ArrowUp } from "assets/icons/common/arrow-up.svg";
import { EditorModes } from "components/app/CodeEditor/EditorConfig";
import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { PropertyPopoverMaxHeight } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { GeneratedTheme } from "legacy/themes";
import {
  EMPTY_RADIUS,
  DEFAULT_CONTAINER_BORDER_OBJECT,
} from "legacy/themes/constants";
import {
  DEFAULT_INPUT_TEXT_STYLE_VARIANT,
  SB_CUSTOM_TEXT_STYLE,
} from "legacy/themes/typographyConstants";
import {
  DATE_INPUT_FORMATS,
  DATE_OUTPUT_FORMATS,
  NUMBER_FORMATTING_OPTIONS,
  TIMEZONE_OPTIONS,
} from "legacy/utils/FormatUtils";
import {
  BooleanStyleFalse,
  ColumnProperties,
  ColumnTypes,
  CompactModeTypes,
  EditInputType,
  ImageSize,
  PaginationTypes,
  DEFAULT_CELL_TEXT_STYLE_VARIANT,
  DEFAULT_HEADING_TEXT_STYLE_VARIANT,
  TABLE_COLUMN_HEADER_DEFAULT_TYPOGRAPHY,
  TableSortColumn,
  TextSizes,
} from "legacy/widgets/TableWidget/TableComponent/Constants";
import {
  textStyleProperty,
  customStylesProperties,
  textColorProperty,
  typographyProperties,
  backgroundColorProperty,
  textStyleCombinedProperty,
  styleProperties,
  borderProperty,
  borderRadiusProperty,
} from "legacy/widgets/styleProperties";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { Flag } from "store/slices/featureFlags";
import {
  getDottedPathTo,
  getParentPath,
  splitJSPath,
  getFirstPathSegments,
} from "utils/dottedPaths";
import { DEFAULT_INPUT_BORDER_OBJECT } from "../InputWidget/InputWidgetConstants";
import {
  iconPositionProperty,
  iconProperty,
  maximumFractionDigitsProperty,
  minimumFractionDigitsProperty,
} from "../appearanceProperties";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { TableWidgetProps } from "./TableWidgetConstants";
import type { DropDownControlOption } from "legacy/components/propertyControls/DropDownControl";
import type { OptionsCustomizerFn } from "legacy/components/propertyControls/TextStyle/types";

const UNEDITABLE_DISPLAY_TYPES = ["button"];
export const getCanToggleEditable = (column: ColumnProperties) => {
  return (
    column.columnType != null &&
    !UNEDITABLE_DISPLAY_TYPES.includes(column.columnType)
  );
};

const themeHasVariant = (theme?: GeneratedTheme, variant?: string) => {
  return get(theme?.typographies, variant ?? "") != null;
};

const getTableVariant = (props: any) => {
  return get(props, "cellProps.textStyle.variant");
};

const getTableCellColor = (props: any) => {
  return get(props, "cellProps.textStyle.textColor.default");
};

const getColumnVariant = (props: any, path: string) => {
  return get(
    props,
    getFirstPathSegments(path, 2) + ".cellProps.textStyle.variant",
  );
};

const LOW_PRIORITY_SORT_TYPES = new Set(["image", "video", "button", "tags"]);

type TextSizeOption = {
  label: string;
  value: TextSizes;
  subText: string;
};

export const getTextSizeOptions = (): Array<TextSizeOption> => {
  return [
    {
      label: "Heading 1",
      value: TextSizes.HEADING1,
      subText: "24px",
    },
    {
      label: "Heading 2",
      value: TextSizes.HEADING2,
      subText: "18px",
    },
    {
      label: "Heading 3",
      value: TextSizes.HEADING3,
      subText: "16px",
    },
    {
      label: "Paragraph",
      value: TextSizes.PARAGRAPH,
      subText: "14px",
    },
    {
      label: "Paragraph 2",
      value: TextSizes.PARAGRAPH2,
      subText: "12px",
    },
  ];
};

export const getEditTypeFromColumnType = (columnType: string) => {
  switch (columnType) {
    case "number":
    case "currency":
    case "percentage":
      return EditInputType.Number;
    case "boolean":
      return EditInputType.Checkbox;
    case "tags":
      return EditInputType.Dropdown;
    case "date":
      return EditInputType.Date;
    case "email":
      return EditInputType.Email;
    default:
      return EditInputType.Text;
  }
};

export const defaultDropdownOptions = (
  widgetName: string,
  columnName: string,
  isDerived: boolean,
) => {
  if (isDerived) {
    return `{{${JSON.stringify(
      [
        {
          label: "New York",
          value: "NEW YORK",
        },
        {
          label: "Toronto",
          value: "TORONTO",
        },
      ],
      null,
      2,
    )}}}`;
  }
  return `
{{
Array.from(new Set(
  _.flatten(
    ${widgetName}.tableData.map(
      ({ ${columnName} }) => ${columnName}
    )
  )
))
}}`;
};

export const columnCellTextStyleOptionsCustomizer: OptionsCustomizerFn = ({
  options,
  props,
  propertyName,
}) => {
  // Get the legacy value and current value
  const legacyTextSizePath =
    getFirstPathSegments(propertyName, 2) + ".textSize";
  const textSize = get(props, legacyTextSizePath);
  const columnVariant = get(props, propertyName);
  const tableVariant = getTableVariant(props);
  const currentVariant = columnVariant ?? tableVariant;

  // Is there a `legacy` value that's not the default, and no new variant value? Return the legacy value to show as selected
  if (textSize != null && currentVariant == null) {
    const option = getTextSizeOptions().find((option) => {
      return option.value === textSize;
    });

    if (!option) return options;

    const legacyOption: DropDownControlOption = {
      ...option,
      label: `${option.label} (legacy)`,
      disabled: true,
      help: {
        warning: {
          message:
            "This text style is legacy. You can upgrade by selecting a text style from the app theme below",
          level: "info",
        },
      },
    };

    return [legacyOption, ...options];
  } else if (columnVariant == null && tableVariant != null) {
    // If there is a new value, we need to show the new value as selected
    return options.map((option) => {
      if (option.value === tableVariant) {
        return {
          ...option,
          label: `Table default (${option.label})`,
        };
      }
      return option;
    });
  }

  return options;
};

export const tableCellTextStyleOptionsCustomizer: OptionsCustomizerFn = ({
  options,
  props,
}) => {
  // Is there a `legacy` value that's not the default, and no new variant value? Return the legacy value to show as selected
  if (
    props.textSize != null &&
    // props.textSize !== LEGACY_DEFAULT_CELL_TEXT_SIZE &&
    getTableVariant(props) == null
  ) {
    const option = getTextSizeOptions().find((option) => {
      return option.value === props.textSize;
    });

    if (!option) return options;

    const legacyOption: DropDownControlOption = {
      ...option,
      label: `${option.label} (legacy)`,
      disabled: true,
      help: {
        warning: {
          message:
            "This text style is legacy. You can upgrade by selecting a text style from the app theme below",
          level: "info",
        },
      },
    };

    return [legacyOption, ...options];
  }

  return options;
};

const getVisibilityForEditTypes =
  (
    editTypes: Array<EditInputType>,
    hideForType: boolean,
    showForEditType: "UPDATE" | "INSERT" | "BOTH" = "BOTH",
  ) =>
  (props: TableWidgetProps, propertyPath: string) => {
    const isEditable = get(
      props,
      `${getParentPath(propertyPath)}.isEditable`,
      false,
    );

    const isEditableOnInsertion =
      props.enableRowInsertion &&
      get(props, `${getParentPath(propertyPath)}.isEditableOnInsertion`, false);
    const inputType = get(
      props,
      `${getParentPath(propertyPath)}.editInputType`,
      false,
    );
    const hideForTypeValue = hideForType
      ? editTypes.includes(inputType)
      : !editTypes.includes(inputType);
    if (showForEditType === "UPDATE") {
      return !isEditable || hideForTypeValue;
    } else if (showForEditType === "INSERT") {
      return !isEditableOnInsertion && !hideForTypeValue;
    }
    return (!isEditable && !isEditableOnInsertion) || hideForTypeValue;
  };

// A hook for handling property updates when the primaryColumns
// has changed and it is supposed to update the derivedColumns
// For example, when we add a new column or update a derived column's name
// The propertyPath will be of the type `primaryColumns.columnId`
const updateDerivedColumnsHook = ({
  props,
  propertyPath,
  propertyValue,
}: {
  props: TableWidgetProps;
  propertyPath: string;
  propertyValue: any;
}): Array<{ propertyPath: string; propertyValue: any }> | undefined => {
  let propertiesToUpdate: Array<{
    propertyPath: string;
    propertyValue: any;
  }> = [];
  if (props && propertyValue) {
    // If we're inserting a new column, we need to add it to the `derivedColumns` property as well
    if (
      /^primaryColumns.+/.test(propertyPath) &&
      typeof propertyValue === "object" &&
      // Validates that it's a newly added column with an id
      propertyValue?.id
    ) {
      const newId = propertyValue.id;
      if (newId) {
        propertiesToUpdate = [
          {
            propertyPath: `derivedColumns${getDottedPathTo(newId)}`,
            propertyValue,
          },
        ];
      }

      const oldColumnOrder = props.columnOrder || [];
      const newColumnOrder = [...oldColumnOrder, propertyValue.id];
      propertiesToUpdate.push({
        propertyPath: "columnOrder",
        propertyValue: newColumnOrder,
      });
    }
    // If we're updating a derived columns, copy the changes to `derivedColumns`
    const regex = /^primaryColumns.+$/;
    if (regex.test(propertyPath)) {
      const matches = splitJSPath(propertyPath);
      if (!matches) {
        return;
      }
      const filteredMatches = matches.filter((m) => m !== ".");
      if (filteredMatches.length === 3) {
        const columnId = filteredMatches[1];
        const columnProperty = filteredMatches[2];
        const primaryColumn = get(props.primaryColumns, columnId);
        const isDerived = primaryColumn ? primaryColumn.isDerived : false;

        const { derivedColumns = {} } = props;

        if (isDerived && derivedColumns && derivedColumns[columnId]) {
          propertiesToUpdate = [
            {
              propertyPath: `derivedColumns${getDottedPathTo(
                columnId,
              )}${getDottedPathTo(columnProperty)}`,
              propertyValue: propertyValue,
            },
          ];
        }
      }
    }
    if (propertiesToUpdate.length > 0) return propertiesToUpdate;
  }
};

export const updateEditablePropertiesHook = ({
  props,
  propertyPath,
  propertyValue,
}: {
  props: TableWidgetProps;
  propertyPath: string;
  propertyValue: any;
}): Array<{ propertyPath: string; propertyValue: any }> | undefined => {
  const propertiesToUpdate: Array<{
    propertyPath: string;
    propertyValue: any;
  }> = [];
  const isEditable = get(props, `${getParentPath(propertyPath)}.isEditable`);
  const isEditableOnInsertion =
    props.enableRowInsertion &&
    get(props, `${getParentPath(propertyPath)}.isEditableOnInsertion`);
  // if changing to an uneditable type, set isEditable to false
  if (
    propertyPath != null &&
    UNEDITABLE_DISPLAY_TYPES.includes(propertyValue) &&
    (isEditable || isEditableOnInsertion)
  ) {
    if (isEditable) {
      propertiesToUpdate.push({
        propertyPath: `${getParentPath(propertyPath)}.isEditable`,
        propertyValue: false,
      });
    }
    if (isEditableOnInsertion) {
      propertiesToUpdate.push({
        propertyPath: `${getParentPath(propertyPath)}.isEditableOnInsertion`,
        propertyValue: false,
      });
    }
  } else {
    let settingToDropdown = false;
    let settingToCheckbox = false;
    if (
      propertyPath != null &&
      propertyPath.includes(".columnType") &&
      isEditable
    ) {
      const editInputType = getEditTypeFromColumnType(propertyValue);
      if (
        get(props, `${getParentPath(propertyPath)}.editInputType`) !==
        editInputType
      ) {
        if (editInputType === EditInputType.Dropdown) settingToDropdown = true;
        if (editInputType === EditInputType.Checkbox) settingToCheckbox = true;
        // If changing the column type of an editable column, update the edit type
        propertiesToUpdate.push({
          propertyPath: `${getParentPath(propertyPath)}.editInputType`,
          propertyValue: editInputType,
        });
      }
    }
    if (
      settingToDropdown ||
      (propertyPath != null &&
        propertyPath.includes(".editInputType") &&
        propertyValue === EditInputType.Dropdown &&
        !get(props, `${getParentPath(propertyPath)}.editOptions`))
    ) {
      const pieces = propertyPath.split(".");
      const columnName = pieces[pieces.length - 2];
      const isDerived = props.derivedColumns?.[columnName] != null;
      propertiesToUpdate.push({
        propertyPath: `${getParentPath(propertyPath)}.editOptions`,
        propertyValue: defaultDropdownOptions(
          props.widgetName,
          columnName,
          isDerived,
        ),
      });
      propertiesToUpdate.push({
        propertyPath: `${getParentPath(propertyPath)}.useLabelAsDisplayValue`,
        propertyValue: true,
      });
      if (
        settingToDropdown ||
        get(props, `${getParentPath(propertyPath)}.columnType`) ===
          ColumnTypes.TAGS
      ) {
        propertiesToUpdate.push({
          propertyPath: `${getParentPath(propertyPath)}.editMultiSelect`,
          propertyValue: true,
        });
      }
    }

    if (
      settingToCheckbox ||
      (propertyPath != null &&
        propertyPath.includes(".editInputType") &&
        propertyValue === EditInputType.Checkbox)
    ) {
      propertiesToUpdate.push({
        propertyPath: `${getParentPath(propertyPath)}.booleanStyleFalse`,
        propertyValue: BooleanStyleFalse.EMPTY_CHECKBOX,
      });
    }
  }

  if (propertiesToUpdate.length > 0) return propertiesToUpdate;
};

const updateDerivedAndEditableHook = ({
  props,
  propertyPath,
  propertyValue,
}: {
  props: TableWidgetProps;
  propertyPath: string;
  propertyValue: any;
}) => {
  const derivedUpdates = updateDerivedColumnsHook({
    props,
    propertyPath,
    propertyValue,
  });
  const editUpdates = updateEditablePropertiesHook({
    props,
    propertyPath,
    propertyValue,
  });
  return [...(derivedUpdates ?? []), ...(editUpdates ?? [])];
};

export const updateIsEditableColumnHook = ({
  props,
  propertyPath,
  propertyValue,
}: {
  props: TableWidgetProps;
  propertyPath: string;
  propertyValue: any;
}) => {
  const derivedProperties = updateDerivedColumnsHook({
    props,
    propertyPath,
    propertyValue,
  });
  if (propertyValue === true) {
    const editInputType = get(
      props,
      `${getParentPath(propertyPath)}.editInputType`,
    );
    const columnType = get(props, `${getParentPath(propertyPath)}.columnType`);
    if (!editInputType) {
      const newEditInputType = getEditTypeFromColumnType(columnType);
      const updates: Array<{
        propertyPath: string;
        propertyValue: any;
      }> = [
        {
          propertyPath: `${getParentPath(propertyPath)}.editInputType`,
          propertyValue: newEditInputType,
        },
      ];
      if (newEditInputType === EditInputType.Dropdown) {
        const pieces = propertyPath.split(".");
        const columnName = pieces[pieces.length - 2];
        const isDerived = props.derivedColumns?.[columnName] != null;
        updates.push({
          propertyPath: `${getParentPath(propertyPath)}.editOptions`,
          propertyValue: defaultDropdownOptions(
            props.widgetName,
            columnName,
            isDerived,
          ),
        });
        updates.push({
          propertyPath: `${getParentPath(propertyPath)}.editMultiSelect`,
          propertyValue: true,
        });
        updates.push({
          propertyPath: `${getParentPath(
            propertyPath,
          )}.editDropdownClientSideFiltering`,
          propertyValue: true,
        });
      } else if (newEditInputType === EditInputType.Checkbox) {
        updates.push({
          propertyPath: `${getParentPath(propertyPath)}.booleanStyleFalse`,
          propertyValue: BooleanStyleFalse.EMPTY_CHECKBOX,
        });
      }

      return [...(derivedProperties ?? []), ...updates];
    }
  }
  return derivedProperties;
};

const enableInsertionUpdateHook = ({
  props,
  propertyValue,
}: {
  props: TableWidgetProps;
  propertyValue: any;
}) => {
  // if this is set to true, set isEdtiableOnInsertion to true for everything
  if (propertyValue === true) {
    const updates: Array<{
      propertyPath: string;
      propertyValue: any;
    }> = [];
    Object.values(props.primaryColumns).forEach((column, index) => {
      if (
        column.isEditableOnInsertion == null &&
        !UNEDITABLE_DISPLAY_TYPES.includes(column.columnType)
      ) {
        updates.push({
          propertyPath: `primaryColumns.${column.id}.isEditableOnInsertion`,
          propertyValue: true,
        });
      } else if (
        column.isEditableOnInsertion === true &&
        UNEDITABLE_DISPLAY_TYPES.includes(column.columnType)
      ) {
        updates.push({
          propertyPath: `primaryColumns.${column.id}.isEditableOnInsertion`,
          propertyValue: false,
        });
      }
      // If there are any primary Columns still referring to tableData as currentRow,
      // they need to be updated to refer to tableDataWithInserts
      Object.entries(column).forEach(([propName, propValue]) => {
        if (
          typeof propValue === "string" &&
          propValue.includes("tableData.map((currentRow)")
        ) {
          updates.push({
            propertyPath: `primaryColumns.${column.id}.${propName}`,
            propertyValue: propValue.replace(
              "tableData.map((currentRow)",
              "tableDataWithInserts.map((currentRow)",
            ),
          });
        }
      });
    });

    return updates;
  }
};

const hiddenWhenNoHeaderIcon = (
  props: TableWidgetProps,
  propertyPath: string,
) => {
  const icon = get(props, `${getParentPath(propertyPath)}.headerIcon`);
  return !icon;
};

const hiddenWhenNoTextIcon = (
  props: TableWidgetProps,
  propertyPath: string,
) => {
  const icon = get(props, `${getParentPath(propertyPath)}.cellIcon`);
  return (
    !icon ||
    hiddenFuncByColumnTypes(["text", "email"], true)(props, propertyPath)
  );
};

const hiddenWhenNoButtonIcon = (
  props: TableWidgetProps,
  propertyPath: string,
) => {
  const icon = get(props, `${getParentPath(propertyPath)}.cellIcon`);
  return (
    !icon || hiddenFuncByColumnTypes(["button"], true)(props, propertyPath)
  );
};

const hiddenFuncByColumnTypes = (columnTypes: string[], excluding = false) => {
  return (props: TableWidgetProps, propertyPath: string) => {
    const baseProperty = getParentPath(propertyPath);
    const columnType = get(props, `${baseProperty}.columnType`, "");
    return excluding
      ? !columnTypes.includes(columnType)
      : columnTypes.includes(columnType);
  };
};

const hiddenForNonEditableColumns = (
  props: TableWidgetProps,
  propertyPath: string,
) => {
  const isEditable = get(
    props,
    `${getParentPath(propertyPath)}.isEditable`,
    false,
  );
  const isEditableOnInsertion =
    props.enableRowInsertion &&
    get(props, `${getParentPath(propertyPath)}.isEditableOnInsertion`, false);

  return !isEditable && !isEditableOnInsertion;
};

const THEME_STYLE_DEFAULTS = {
  primaryTextColor: "dynamic",
  primaryBackgroundColor: "colors.primary500",
  secondaryTextColor: "colors.primary500",
  secondaryBorderColor: "colors.primary500",
  tertiaryTextColor: "colors.primary500",
  cellTextColor: "colors.neutral700",
  celBackgroundColor: "colors.neutral",
  linkTextcolor: "colors.primary500",
};

const SORT_ICONS: Record<"Ascending" | "Descending", React.ReactNode> = {
  Ascending: <ArrowUp />,
  Descending: <ArrowDown />,
};

const config: PropertyPaneConfig[] = [
  {
    sectionName: "General",
    sectionCategory: PropsPanelCategory.Content,
    children: [
      {
        helpText: "A header displayed at the top of the table",
        propertyName: "tableHeader",
        label: "Header",
        controlType: "INPUT_TEXT",
        placeholderText: "Table name",
        inputType: "text",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "Table name",
      },
      {
        helpText:
          "Takes in an array of objects to display rows in the table. Bind data from an API using {{}}",
        propertyName: "tableData",
        label: "Data",
        controlType: "INPUT_TEXT",
        placeholderText: 'Enter [{ "col1": "val1" }]',
        inputType: "ARRAY",
        forceVertical: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        helpText: "Columns",
        propertyName: "primaryColumns",
        controlType: "PRIMARY_COLUMNS",
        label: "Columns",
        updateHook: updateDerivedColumnsHook,
        isBindProperty: false,
        isTriggerProperty: false,
        headerControlType: "ADD_COLUMN",
        panelConfig: {
          title: "Edit column",
          editableTitle: false,
          titlePropertyName: undefined,
          panelIdPropertyName: "id",
          showEditIcon: false,
          adaptiveHeight: { maxHeight: PropertyPopoverMaxHeight },
          updateHook: updateDerivedColumnsHook,
          children: [
            {
              sectionName: "Column Control",
              sectionCategory: PropsPanelCategory.Content,
              children: [
                {
                  propertyName: "label",
                  label: "Column header",
                  controlType: "INPUT_TEXT",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "columnType",
                  label: "Data type",
                  controlType: "DROP_DOWN",
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  options: [
                    {
                      label: "Plain text",
                      value: "text",
                    },
                    {
                      label: "Number",
                      value: "number",
                    },
                    {
                      label: "Currency",
                      value: "currency",
                    },
                    {
                      label: "Percentage",
                      value: "percentage",
                    },
                    {
                      label: "Image",
                      value: "image",
                    },
                    {
                      label: "Video",
                      value: "video",
                    },
                    {
                      label: "Date",
                      value: "date",
                    },
                    {
                      label: "Button",
                      value: "button",
                    },
                    {
                      label: "Link",
                      value: "link",
                    },
                    {
                      label: "Boolean",
                      value: "boolean",
                    },
                    {
                      label: "Tags",
                      value: "tags",
                    },
                    {
                      label: "Email",
                      value: "email",
                    },
                  ],
                  updateHook: updateDerivedAndEditableHook,
                  isBindProperty: false,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "computedValue",
                  label: "Computed value",
                  controlType: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType === "button" || columnType === "link";
                  },
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "displayedValue",
                  label: "Displayed value",
                  controlType: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== ColumnTypes.TEXT;
                  },
                  isBindProperty: true,
                  isTriggerProperty: false,
                  visibility: "SHOW_NAME",
                  isRemovable: true,
                  defaultValueFn: ({
                    props,
                    propertyName,
                  }: {
                    propertyName: string;
                    props: TableWidgetProps;
                  }) => {
                    const baseProperty = getParentPath(propertyName);
                    const columnId = get(props, `${baseProperty}.id`, "");
                    if (!columnId) {
                      return "text";
                    }
                    return `{{currentRow.${columnId}}}`;
                  },
                },
                {
                  propertyName: "buttonLabel",
                  label: "Label",
                  controlType: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: hiddenFuncByColumnTypes(["button"], true),
                },
                {
                  propertyName: "isDisabled",
                  label: "Disabled",
                  helpText: "Disables user interaction with this component",
                  controlType: "SWITCH",
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: VALIDATION_TYPES.BOOLEAN,
                  hidden: hiddenFuncByColumnTypes(["button"], true),
                },
                {
                  propertyName: "currency",
                  helpText: "The three letter ISO 4217 currency code",
                  label: "Currency code",
                  controlType: "DROP_DOWN",
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  defaultValue: "USD",
                  options: CurrencyList.map((currency) => ({
                    label: currency,
                    value: currency,
                  })),
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "currency";
                  },
                  isBindProperty: true,
                  isJSConvertible: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "notation",
                  helpText: "The display format of the number",
                  label: "Number format",
                  controlType: "DROP_DOWN",
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  defaultValue: "standard",
                  options: NUMBER_FORMATTING_OPTIONS,
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "number" && columnType !== "currency";
                  },
                  isBindProperty: false,
                  isTriggerProperty: false,
                  propertyCategory: PropsPanelCategory.Appearance,
                },
                minimumFractionDigitsProperty({
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return (
                      columnType !== "number" &&
                      columnType !== "currency" &&
                      columnType !== "percentage"
                    );
                  },
                  isBindProperty: false,
                }),
                maximumFractionDigitsProperty({
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return (
                      columnType !== "number" &&
                      columnType !== "currency" &&
                      columnType !== "percentage"
                    );
                  },
                  isBindProperty: false,
                }),
                {
                  propertyName: "linkUrl",
                  label: "Link URL",
                  controlType: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "link";
                  },
                },
                {
                  propertyName: "linkLabel",
                  label: "Link label",
                  controlType: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "link";
                  },
                },
                {
                  propertyName: "openInNewTab",
                  label: "Open in a new tab",
                  controlType: "SWITCH",
                  defaultValue: true,
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "link";
                  },
                },
                {
                  propertyName: "inputFormat",
                  helpText: "Sets the format of the selected date",
                  label: "Value format",
                  controlType: "DROP_DOWN",
                  options: DATE_INPUT_FORMATS,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  isJSConvertible: true,
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "date";
                  },
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "outputFormat",
                  helpText: "Sets the format of the date displayed in the UI",
                  label: "Display format",
                  controlType: "DROP_DOWN",
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  isJSConvertible: true,
                  options: DATE_OUTPUT_FORMATS,
                  updateHook: updateDerivedColumnsHook,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    if (!propertyPath) {
                      return false;
                    }
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "date";
                  },
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  helpText:
                    "Allows you to control the timezone of the original dates and displayed dates",
                  propertyName: "manageTimezone",
                  label: "Manage timezone",
                  controlType: "SWITCH",
                  isJSConvertible: false,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    if (!propertyPath) {
                      return false;
                    }
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    return columnType !== "date";
                  },
                },
                {
                  helpText: "Timezone of the original date",
                  propertyName: "timezone",
                  label: "Value timezone",
                  controlType: "DROP_DOWN",
                  isJSConvertible: false,
                  options: TIMEZONE_OPTIONS,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    if (!propertyPath) {
                      return false;
                    }
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    const manageTimezone = get(
                      props,
                      `${
                        baseProperty
                          ? `${baseProperty}.manageTimezone`
                          : "manageTimezone"
                      }`,
                      "",
                    );
                    return columnType !== "date" || !manageTimezone;
                  },
                },
                {
                  helpText: "Timezone of the displayed date",
                  propertyName: "displayTimezone",
                  label: "Display timezone",
                  controlType: "DROP_DOWN",
                  isJSConvertible: false,
                  options: TIMEZONE_OPTIONS,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    if (!propertyPath) {
                      return false;
                    }
                    const baseProperty = getParentPath(propertyPath);
                    const columnType = get(
                      props,
                      `${baseProperty}.columnType`,
                      "",
                    );
                    const manageTimezone = get(
                      props,
                      `${
                        baseProperty
                          ? `${baseProperty}.manageTimezone`
                          : "manageTimezone"
                      }`,
                      "",
                    );
                    return columnType !== "date" || !manageTimezone;
                  },
                },
                {
                  helpText: "Controls the visibility of the column",
                  propertyName: "isVisible",
                  isJSConvertible: true,
                  label: "Visible",
                  controlType: "SWITCH",
                  isBindProperty: true,
                  updateHook: updateDerivedColumnsHook,
                  isTriggerProperty: false,
                },
              ],
            },
            {
              sectionName: "Presentation",
              showHeader: true,
              headerType: "Collapse",
              sectionCategory: PropsPanelCategory.Appearance,
              children: [
                iconProperty({
                  propertyName: "headerIcon",
                  label: "Header icon",
                  helpText:
                    "Select an icon to be displayed alongside the column header",
                  isBindProperty: false,
                }),
                iconPositionProperty({
                  propertyName: "headerIconPosition",
                  helpText: "Select the position of the header icon",
                  label: "Header icon position",
                  hidden: hiddenWhenNoHeaderIcon,
                }),
                iconProperty({
                  propertyName: "cellIcon",
                  label: "Cell icon",
                  helpText:
                    "Select an icon to be displayed alongside the value in the table",
                  customJSControl: "COMPUTE_TABLE_ICON_SELECTOR",
                  isBindProperty: false,
                  hidden: hiddenFuncByColumnTypes(["text", "email"], true),
                }),
                iconPositionProperty({
                  propertyName: "cellIconPosition",
                  helpText: "Select the position of the cell icon",
                  label: "Cell icon position",
                  hidden: hiddenWhenNoTextIcon,
                }),
                iconProperty({
                  propertyName: "cellIcon",
                  label: "Button icon",
                  helpText: "Select an icon",
                  customJSControl: "COMPUTE_TABLE_ICON_SELECTOR",
                  isBindProperty: false,
                  hidden: hiddenFuncByColumnTypes(["button"], true),
                }),
                iconPositionProperty({
                  propertyName: "cellIconPosition",
                  helpText: "Sets the alignment of the icon",
                  label: "Button icon position",
                  hidden: hiddenWhenNoButtonIcon,
                }),
                {
                  propertyName: "textWrap",
                  label: "Wrap text",
                  controlType: "SWITCH",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: VALIDATION_TYPES.BOOLEAN,
                  hidden: hiddenFuncByColumnTypes([
                    "boolean",
                    "link",
                    "button",
                    "image",
                    "video",
                    "tags",
                  ]),
                  helpText: "If enabled, the text will wrap within the cell",
                },
                {
                  propertyName: "openImageUrl",
                  label: "Open URL on click",
                  controlType: "SWITCH",
                  helpText:
                    "Opens the image in a new tab when the image is clicked",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  defaultValue: true,
                  hidden: (
                    props: TableWidgetProps,
                    propertyPath: string,
                    flags,
                  ) => {
                    const columnType = get(
                      props,
                      `${propertyPath}.columnType`,
                      "",
                    );
                    return (
                      !flags[Flag.ENABLE_TABLE_IMAGE_SIZE] ||
                      columnType !== "image"
                    );
                  },
                },
              ],
            },
            // Image Styles
            {
              sectionName: "Styles",
              sectionCategory: PropsPanelCategory.Appearance,
              key: "imageStyles",
              showHeader: true,
              headerType: "Collapse",
              hidden: (
                props: TableWidgetProps,
                propertyPath: string,
                flags,
              ) => {
                const columnType = get(props, `${propertyPath}.columnType`, "");
                return (
                  !flags[Flag.ENABLE_TABLE_IMAGE_SIZE] || columnType !== "image"
                );
              },
              children: [
                {
                  propertyName: "imageSize",
                  label: "Image sizing",
                  controlType: "DROP_DOWN",
                  helpText: "Controls the sizing behavior of the image",
                  options: [
                    {
                      label: "Fixed",
                      value: ImageSize.Fixed,
                      subText:
                        "Image is cropped to a fixed size based on the table row size",
                    },
                    {
                      label: "Fit",
                      value: ImageSize.Fit,
                      subText:
                        "Image will fill as much space as possible while maintaining the aspect ratio",
                    },
                    {
                      label: "Cover",
                      value: ImageSize.Cover,
                      subText:
                        "Image covers the entire cell and is clipped horizontally or vertically as needed",
                    },
                    {
                      label: "Grow vertically",
                      value: ImageSize.Grow,
                      subText:
                        "Image fills the cell's width. If necessary, the row height will grow to maintain the aspect ratio",
                    },
                  ],
                  isBindProperty: true,
                  isJSConvertible: true,
                  isTriggerProperty: false,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  defaultValue: ImageSize.Fixed,
                },
                {
                  propertyName: "imageBorderRadius",
                  label: "Image border radius",
                  controlType: "DROP_DOWN",
                  helpText: "Controls the border radius of the image",
                  options: [
                    {
                      label: "0px",
                      value: Dimension.px(0),
                    },
                    {
                      label: "4px",
                      value: Dimension.px(4),
                    },
                    {
                      label: "50% (circular)",
                      value: { mode: "%", value: 50 },
                    },
                  ],
                  isBindProperty: true,
                  isJSConvertible: true,
                  isTriggerProperty: false,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  defaultValue: { mode: "%", value: 50 },
                  optionValueToKey: (value: Dimension) => {
                    return `${value?.value}${value?.mode}`;
                  },
                },
              ],
            },
            {
              // Button Styles
              sectionName: "Styles",
              sectionCategory: PropsPanelCategory.Appearance,
              key: "buttonStyles",
              showHeader: true,
              headerType: "Collapse",
              hidden: (props: TableWidgetProps, propertyPath: string) => {
                const columnType = get(props, `${propertyPath}.columnType`, "");
                return columnType !== "button";
              },
              children: [
                // primary button styles
                {
                  propertyName: "buttonVariant",
                  label: "Button style",
                  controlType: "DROP_DOWN",
                  helpText: "Changes the style of the button",
                  options: [
                    {
                      label: "Primary button",
                      value: "PRIMARY_BUTTON",
                    },
                    {
                      label: "Secondary button",
                      value: "SECONDARY_BUTTON",
                    },
                    {
                      label: "Tertiary button",
                      value: "TERTIARY_BUTTON",
                    },
                  ],
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  defaultValue: "PRIMARY_BUTTON",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "buttonStyle",
                  label: "Background",
                  controlType: "COLOR_PICKER",
                  helpText: "Changes the color of the button",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  themeValue: THEME_STYLE_DEFAULTS.primaryBackgroundColor,
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const buttonType = get(
                      props,
                      `${getParentPath(propertyPath)}.buttonVariant`,
                      "",
                    );
                    return buttonType && buttonType !== "PRIMARY_BUTTON";
                  },
                },
                {
                  propertyName: "buttonLabelColor",
                  helpText: "Changes the color of the label",
                  label: "Label color",
                  controlType: "COLOR_PICKER",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  themeValue: THEME_STYLE_DEFAULTS.primaryTextColor,
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const buttonType = get(
                      props,
                      `${getParentPath(propertyPath)}.buttonVariant`,
                      "",
                    );
                    return buttonType && buttonType !== "PRIMARY_BUTTON";
                  },
                },
                //secondary button styles
                {
                  propertyName: "buttonStyle",
                  label: "Border",
                  controlType: "COLOR_PICKER",
                  helpText: "Changes the color of the button",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  themeValue: THEME_STYLE_DEFAULTS.secondaryBorderColor,
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const buttonType = get(
                      props,
                      `${getParentPath(propertyPath)}.buttonVariant`,
                      "",
                    );
                    return buttonType !== "SECONDARY_BUTTON";
                  },
                },
                {
                  propertyName: "buttonLabelColor",
                  helpText: "Changes the color of the label",
                  label: "Label color",
                  controlType: "COLOR_PICKER",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  themeValue: THEME_STYLE_DEFAULTS.secondaryTextColor,
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const buttonType = get(
                      props,
                      `${getParentPath(propertyPath)}.buttonVariant`,
                      "",
                    );
                    return buttonType !== "SECONDARY_BUTTON";
                  },
                },
                // tertiary button styles
                {
                  propertyName: "buttonLabelColor",
                  helpText: "Changes the color of the label",
                  label: "Label color",
                  controlType: "COLOR_PICKER",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  themeValue: THEME_STYLE_DEFAULTS.tertiaryTextColor,
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    const buttonType = get(
                      props,
                      `${getParentPath(propertyPath)}.buttonVariant`,
                      "",
                    );
                    return buttonType !== "TERTIARY_BUTTON";
                  },
                },
              ],
            },
            {
              sectionName: "Styles",
              sectionCategory: PropsPanelCategory.Appearance,
              key: "textStyles",
              showHeader: true,
              headerType: "Collapse",
              hidden: (props: TableWidgetProps, propertyPath: string) => {
                if (!propertyPath) {
                  return false;
                }
                const columnType = get(props, `${propertyPath}.columnType`, "");

                return (
                  columnType === "button" ||
                  columnType === "image" ||
                  columnType === "video" ||
                  columnType === "tags"
                );
              },
              children: [
                {
                  propertyName: "horizontalAlignment",
                  helpText:
                    "The horizontal alignment of the text relative to the cell width",
                  label: "Horizontal align",
                  controlType: "RADIO_BUTTON_GROUP",
                  options: [
                    {
                      icon: "LEFT_ALIGN",
                      value: "LEFT",
                    },
                    {
                      icon: "CENTER_ALIGN",
                      value: "CENTER",
                    },
                    {
                      icon: "RIGHT_ALIGN",
                      value: "RIGHT",
                    },
                  ],
                  defaultValue: "LEFT",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "verticalAlignment",
                  helpText:
                    "The vertical alignment of the text relative to the cell height",
                  label: "Vertical align",
                  controlType: "RADIO_BUTTON_GROUP",
                  options: [
                    {
                      icon: "VERTICAL_TOP",
                      value: "TOP",
                    },
                    {
                      icon: "VERTICAL_CENTER",
                      value: "CENTER",
                    },
                    {
                      icon: "VERTICAL_BOTTOM",
                      value: "BOTTOM",
                    },
                  ],
                  defaultValue: "LEFT",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: hiddenFuncByColumnTypes(["boolean"]),
                },
                textStyleProperty({
                  textStyleParentDottedPath: "cellProps",
                  label: "Cell text style",
                  helpText:
                    "Sets the style of this specific column. Overrides the cell text style set at the Table level",
                  updateHook: updateDerivedColumnsHook,
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  isBindProperty: true,
                  hidden: (props, propertyPath) => {
                    return hiddenFuncByColumnTypes(["boolean"])(
                      props,
                      propertyPath,
                    );
                  },
                  optionsCustomizer: columnCellTextStyleOptionsCustomizer,
                  getDynamicTextStyleParentDottedPath: (
                    propertyName: string,
                  ) => {
                    // ex: primaryColumns.colName.cellProps
                    return getFirstPathSegments(propertyName, 3);
                  },
                  defaultValueFn: ({ props, propertyName, flags }) => {
                    // Get the legacy value and current value
                    const legacyTextSize = get(
                      props,
                      getFirstPathSegments(propertyName, 2) + ".textSize",
                    );
                    const columnVariant = get(props, propertyName);
                    const tableVariant = getTableVariant(props);
                    const currentVariant = columnVariant ?? tableVariant;

                    // Use legacy text size if there is no variant for both the column and the table
                    if (legacyTextSize != null && currentVariant == null) {
                      return getTextSizeOptions().find((option) => {
                        return option.value === legacyTextSize;
                      })?.value;
                    } else if (columnVariant == null && tableVariant != null) {
                      // if there is no variant for the column but there is for the table, use the table variant
                      return tableVariant;
                    } else {
                      return DEFAULT_CELL_TEXT_STYLE_VARIANT;
                    }
                  },
                  resetToThemeBtnText: "Reset to table default",
                  themeValue: ({ props, propertyName }) => {
                    const tableVariant = getTableVariant(props);

                    if (tableVariant) {
                      return {
                        value: tableVariant,
                        treatAsNull: false,
                      };
                    }

                    return {
                      value: DEFAULT_CELL_TEXT_STYLE_VARIANT,
                      treatAsNull: true,
                    };
                  },
                }),
                ...customStylesProperties<"cellProps">({
                  textStyleParentDottedPath: "cellProps",
                  forceFullPropertyNamePath: true, // `true` because we are not using this in a popover yet in this widget
                  isPopover: false,
                  getDynamicTextStyleParentDottedPath: (
                    propertyName: string,
                  ) => {
                    return getFirstPathSegments(propertyName, 3);
                  },
                  overrideHidden: (props: any, propertyName: string) => {
                    return (
                      get(
                        props,
                        `${getFirstPathSegments(propertyName, 4)}.variant`,
                      ) !== SB_CUSTOM_TEXT_STYLE
                    );
                  },
                }),
                {
                  propertyName: "textColor",
                  label: "Cell text color",
                  controlType: "COLOR_PICKER",
                  themeValue: THEME_STYLE_DEFAULTS.cellTextColor,
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (
                    props,
                    propertyName,
                    flags,
                    _additionalHiddenData,
                    _theme,
                  ) => {
                    const hiddenForColType = hiddenFuncByColumnTypes([
                      "boolean",
                      "link",
                    ])(props, propertyName);

                    const columnVariant = getColumnVariant(props, propertyName);
                    const currentVariant =
                      columnVariant ?? getTableVariant(props);

                    return Boolean(
                      currentVariant !== undefined || hiddenForColType,
                    );
                  },
                },
                {
                  propertyName: "textColor",
                  label: "Cell text color",
                  controlType: "COLOR_PICKER",
                  themeValue: THEME_STYLE_DEFAULTS.linkTextcolor, // this is duplicated so that we use primary500 as the default for links
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (
                    props,
                    propertyName,
                    flags,
                    _additionalHiddenData,
                    _theme,
                  ) => {
                    const hiddenForColType = hiddenFuncByColumnTypes(
                      ["link"],
                      true,
                    )(props, propertyName);

                    const columnVariant = getColumnVariant(props, propertyName);
                    const currentVariant =
                      columnVariant ?? getTableVariant(props);

                    return Boolean(
                      currentVariant !== undefined || hiddenForColType,
                    );
                  },
                },
                textColorProperty({
                  label: "Cell text color",
                  textStyleParentDottedPath: "cellProps",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (
                    props,
                    propertyName,
                    flags,
                    additionalHiddenData,
                    theme,
                  ) => {
                    const columnVariant = getColumnVariant(props, propertyName);
                    const tableVariant = getTableVariant(props);
                    const currentVariant = columnVariant ?? tableVariant;

                    // We only check for undefined here because code mode could be enabled so this value may be a binding
                    return currentVariant === undefined;
                  },
                  themeValue: ({ props, propertyName, theme }) => {
                    const isLinkColumn =
                      get(
                        props,
                        `${getFirstPathSegments(propertyName, 2)}.columnType`,
                      ) === "link";

                    if (isLinkColumn) {
                      return {
                        value: `typographies.link.textColor.default`,
                        treatAsNull: false,
                      };
                    }

                    const tableOverrideColor = getTableCellColor(props);
                    const columnVariant = getColumnVariant(props, propertyName);
                    const tableVariant = getTableVariant(props);
                    const currentVariant = columnVariant ?? tableVariant;

                    if (tableOverrideColor && !columnVariant) {
                      return {
                        value: tableOverrideColor,
                        treatAsNull: false,
                      };
                    } else if (themeHasVariant(theme, currentVariant)) {
                      return {
                        value: `typographies.${currentVariant}.textColor.default`,
                        treatAsNull: false,
                      };
                    } else {
                      return {
                        value: `typographies.${DEFAULT_CELL_TEXT_STYLE_VARIANT}.textColor.default`,
                        treatAsNull: false,
                      };
                    }
                  },
                }),
                {
                  propertyName: "fontStyle",
                  label: "Font style",
                  controlType: "BUTTON_TABS",
                  options: [
                    {
                      icon: "BOLD_FONT",
                      value: "BOLD",
                    },
                    {
                      icon: "ITALICS_FONT",
                      value: "ITALIC",
                    },
                  ],
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: (props, propertyName, flags) => {
                    const colHidden = hiddenFuncByColumnTypes(["boolean"])(
                      props,
                      propertyName,
                    );

                    const columnVariant = get(
                      props,
                      getFirstPathSegments(propertyName, 2) +
                        ".cellProps.textStyle.variant",
                    );
                    const tableVariant = getTableVariant(props);
                    const currentVariant = columnVariant ?? tableVariant;
                    if (currentVariant) {
                      return true;
                    }

                    return colHidden;
                  },
                },
                {
                  propertyName: "cellBackground",
                  helpText: "Sets the background color of the cell",
                  label: "Cell background",
                  controlType: "COLOR_PICKER",
                  isJSConvertible: true,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: hiddenFuncByColumnTypes(["boolean"]),
                  themeValue: THEME_STYLE_DEFAULTS.celBackgroundColor,
                },
                {
                  propertyName: "booleanStyleFalse",
                  label: "False value",
                  controlType: "RADIO_BUTTON",
                  options: [
                    {
                      name: "",
                      value: BooleanStyleFalse.EMPTY,
                    },
                    {
                      name: "Close",
                      isIcon: true,
                      icon: "CLOSE",
                      value: BooleanStyleFalse.CLOSE,
                    },
                    {
                      name: "Minus",
                      isIcon: true,
                      icon: "MINUS",
                      value: BooleanStyleFalse.MINUS,
                    },
                    {
                      name: "Empty checkbox",
                      isIcon: true,
                      icon: "EMPTY_CHECKBOX",
                      value: BooleanStyleFalse.EMPTY_CHECKBOX,
                    },
                  ],
                  defaultValue: BooleanStyleFalse.EMPTY,
                  isJSConvertible: false,
                  customJSControl: "COMPUTE_TABLE_VALUE",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: hiddenFuncByColumnTypes(["boolean"], true),
                },
              ],
            },
            {
              sectionName: "Tags styles",
              sectionCategory: PropsPanelCategory.Appearance,
              children: [
                // general styles section is hidden for tags, we want to let
                {
                  propertyName: "textWrap",
                  label: "Wrap tags",
                  controlType: "SWITCH",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: VALIDATION_TYPES.BOOLEAN,
                  helpText: "If enabled, the tags will wrap within the cell",
                },
              ],
              hidden: hiddenFuncByColumnTypes(["tags"], true),
            },
            {
              sectionName: "Button properties",
              sectionCategory: PropsPanelCategory.EventHandlers,
              hidden: (props: TableWidgetProps, propertyPath: string) => {
                const columnType = get(props, `${propertyPath}.columnType`, "");
                return columnType !== "button";
              },
              children: [
                getPopoverConfig(
                  "onClick",
                  "Triggers an action when the button is clicked",
                  {
                    customJSControl: "COMPUTE_TABLE_VALUE",
                    updateHook: updateDerivedColumnsHook,
                  },
                ),
              ],
            },
            {
              sectionName: "Edit mode",
              sectionCategory: PropsPanelCategory.Interaction,
              hidden: (props: TableWidgetProps, propertyPath: string) => {
                const columnType = get(props, `${propertyPath}.columnType`, "");
                return UNEDITABLE_DISPLAY_TYPES.includes(columnType);
              },
              children: [
                {
                  propertyName: "isEditable",
                  label: "Editable on update",
                  helpText: "Can be edited for an existing row",
                  controlType: "SWITCH",
                  defaultValue: false,
                  updateHook: updateIsEditableColumnHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  isJSConvertible: true,
                },
                {
                  propertyName: "editIsRequired",
                  label: "Required on update",
                  helpText: "Required when editing an existing row",
                  controlType: "SWITCH",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Checkbox],
                    true,
                    "UPDATE",
                  ),
                },
                {
                  propertyName: "isEditableOnInsertion",
                  label: "Editable on insertion",
                  helpText: "Can be edited when inserting a new row",
                  controlType: "SWITCH",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  isJSConvertible: false,
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    return !props.enableRowInsertion;
                  },
                },
                {
                  propertyName: "isRequiredOnInsertion",
                  label: "Required on insertion",
                  helpText: "Required when inserting a new row",
                  controlType: "SWITCH",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Checkbox],
                    true,
                    "INSERT",
                  ),
                },
                {
                  propertyName: "editInputType",
                  label: "Editing type",
                  controlType: "DROP_DOWN",
                  defaultValue: EditInputType.Text,
                  updateHook: updateEditablePropertiesHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  options: [
                    {
                      label: "Text",
                      value: EditInputType.Text,
                    },
                    {
                      label: "Number",
                      value: EditInputType.Number,
                    },
                    {
                      label: "Email",
                      value: EditInputType.Email,
                    },
                    {
                      label: "Dropdown",
                      value: EditInputType.Dropdown,
                    },
                    {
                      label: "Checkbox",
                      value: EditInputType.Checkbox,
                    },
                    {
                      label: "Date picker",
                      value: EditInputType.Date,
                    },
                  ],
                  hidden: hiddenForNonEditableColumns,
                },
                {
                  propertyName: "defaultValue",
                  label: "Default value",
                  controlType: "INPUT_TEXT",
                  placeholderText: "Enter a default value",
                  isJSConvertible: false,
                  isBindProperty: false,
                  isTriggerProperty: true, // to prevent evaluations before insertions
                  hidden: (props: TableWidgetProps, propertyPath: string) => {
                    return !props.enableRowInsertion;
                  },
                },
                // EDIT VALIDATION
                {
                  propertyName: "editMinLength",
                  label: "Min length",
                  controlType: "INPUT_TEXT",
                  inputType: "NUMBER",
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Text],
                    false,
                  ),
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "editMaxLength",
                  label: "Max length",
                  controlType: "INPUT_TEXT",
                  inputType: "NUMBER",
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Text],
                    false,
                  ),
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "editCustomValidationRule",
                  label: "Validation",
                  helpText:
                    "Sets a custom validation rule for the edited table cell.",
                  controlType: "INPUT_TEXT",
                  placeholderText: "{{Table.currentEditValue...}}",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [
                      EditInputType.Text,
                      EditInputType.Number,
                      EditInputType.Email,
                    ],
                    false,
                  ),
                },
                {
                  propertyName: "editCustomErrorMessage",
                  label: "Error",
                  helpText:
                    "Sets a custom message to display in a popover when validation doesn't pass",
                  controlType: "INPUT_TEXT",
                  placeholderText: "Enter validation message",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [
                      EditInputType.Text,
                      EditInputType.Number,
                      EditInputType.Email,
                    ],
                    false,
                  ),
                },
                // dropdown edit options
                {
                  helpText:
                    "Options to display in dropdown. Values must be unique",
                  propertyName: "editOptions",
                  label: "Options",
                  controlType: "TABLE_LABEL_VALUE_CONTROL",
                  placeholderText: '[{label: "label1", value: "value2"}]',
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Dropdown],
                    false,
                  ),
                },
                {
                  helpText:
                    "When enabled, the label of the selected option(s) will be displayed in the table instead of the column’s displayed or computed values",
                  propertyName: "useLabelAsDisplayValue",
                  label: "Use label for cell display",
                  controlType: "SWITCH",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  isJSConvertible: true,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Dropdown],
                    false,
                  ),
                },
                {
                  propertyName: "editMultiSelect",
                  label: "Multi-select",
                  helpText:
                    "Allows users to select either a single option or multiple options",
                  controlType: "SWITCH",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Dropdown],
                    false,
                  ),
                },
                {
                  propertyName: "editDropdownClientSideFiltering",
                  label: "Client-side filtering",
                  helpText:
                    "Enable client-side typeahead filtering of options via fuzzy matching. When implementing server-side filtering, we recommend disabling this setting to avoid placing the entire table into a loading state when fetching options",
                  controlType: "SWITCH",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Dropdown],
                    false,
                  ),
                  defaultValue: true,
                },
                getPopoverConfig(
                  "onDropdownSearchTextChanged",
                  "Triggers an action when a user types to search over the dropdown options. You can access the search text using the currentEditDropdownSearchText property",
                  {
                    propertyCategory: PropsPanelCategory.EventHandlers,
                    hidden: getVisibilityForEditTypes(
                      [EditInputType.Dropdown],
                      false,
                    ),
                  },
                  {
                    customJSControl: "COMPUTE_TABLE_VALUE",
                    updateHook: updateDerivedColumnsHook,
                  },
                  undefined,
                  "onDropdownSearchTextChange",
                ),
                // Date edit options
                {
                  propertyName: "editMinDate",
                  label: "Min date",
                  controlType: "DATE_PICKER",
                  placeholderText: "Minimum Date",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Date],
                    false,
                  ),
                },
                {
                  propertyName: "editMaxDate",
                  label: "Max date",
                  controlType: "DATE_PICKER",
                  placeholderText: "Maximum Date",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Date],
                    false,
                  ),
                },
                {
                  propertyName: "editTwentyFourHourTime",
                  label: "24 hour time",
                  controlType: "SWITCH",
                  defaultValue: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  hidden: getVisibilityForEditTypes(
                    [EditInputType.Date],
                    false,
                  ),
                },
              ],
            },
            {
              sectionName: "Custom Tag Colors",
              sectionCategory: PropsPanelCategory.Appearance,
              hidden: (props: TableWidgetProps, propertyPath: string) => {
                const columnType = get(props, `${propertyPath}.columnType`, "");
                return columnType !== "tags";
              },
              children: [
                {
                  propertyName: "tagDisplayConfig",
                  label: "Custom tag colors",
                  controlType: "CUSTOM_TAG_COLORS",
                  headerControlType: "ADD_TAG_COLOR",
                  customJSControl: "INPUT_JS_EXPR",
                  canExpandEditor: true,
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
              ],
            },
            {
              sectionName: "Frozen",
              sectionCategory: PropsPanelCategory.Appearance,
              children: [
                {
                  propertyName: "isFrozen",
                  helpText:
                    "Freezing a column will keep it visible on the left-hand side of the table when scrolling horizontally",
                  label: "Frozen",
                  controlType: "SWITCH",
                  updateHook: updateDerivedColumnsHook,
                  isBindProperty: true,
                  isTriggerProperty: false,
                },
              ],
            },
          ],
        },
      },
    ],
  },
  sizeSection({ heightSupportsFitContent: true }),
  {
    sectionName: "Table Editing",
    sectionCategory: PropsPanelCategory.Interaction,
    children: [
      {
        propertyName: "isFilterable",
        label: "Filtering",
        helpText: "Enable per column filtering",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
        hidden: (props: TableWidgetProps) =>
          props.pageType === PaginationTypes.SERVER_SIDE,
      },
      {
        propertyName: "defaultFilters",
        label: "Default filters",
        helpText:
          "Allows specifying the filters that will be used on page load",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter default filters",
        isBindProperty: true,
        isTriggerProperty: false,
        hidden: (props: TableWidgetProps) =>
          !props.isFilterable || props.pageType === PaginationTypes.SERVER_SIDE,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "{}",
      },
      {
        propertyName: "enableRowInsertion",
        label: "Row insertion",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        updateHook: enableInsertionUpdateHook,
      },
      {
        propertyName: "enableRowDeletion",
        label: "Row deletion",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "multiRowSelection",
        label: "Multi row selection",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "isSearchable",
        label: "Search",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        helpText: "Toggle visibility of the data download",
        propertyName: "isDownloadable",
        label: "CSV download",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        helpText:
          "For server side pagination bind the Table.pageNo property in your API and call it onPageChange",
        propertyName: "pageType",
        label: "Pagination type",
        controlType: "DROP_DOWN",
        options: [
          { label: "None", value: PaginationTypes.NONE },
          { label: "Client side", value: PaginationTypes.CLIENT_SIDE },
          { label: "Server side", value: PaginationTypes.SERVER_SIDE },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
      },
      {
        helpText:
          'Sets the number of items per page. If set to "Auto", this value will be calculated based on the height of the table.',
        propertyName: "configuredPageSize",
        label: "Page size",
        controlType: "DROP_DOWN",
        defaultValue: -1,
        validation: VALIDATION_TYPES.NUMBER,
        options: [
          { label: "Auto", value: -1 },
          { label: "5", value: 5 },
          { label: "10", value: 10 },
          { label: "20", value: 20 },
          { label: "50", value: 50 },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        isJSConvertible: true,
        hidden: (props: TableWidgetProps) =>
          props.pageType !== PaginationTypes.CLIENT_SIDE,
      },
      {
        propertyName: "defaultSort",
        label: "Default sort",
        helpText:
          "Allows specifying the sort options that will be used on page load",
        controlType: "DEFAULT_TABLE_SORT",
        isBindProperty: !true,
        isTriggerProperty: false,
        isJSConvertible: true,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValueFn: ({
          props,
        }: {
          props: TableWidgetProps;
        }): TableSortColumn => {
          const primaryColumns = props.primaryColumns ?? {};
          let anyColumn: string = "";
          for (const key of Object.keys(primaryColumns)) {
            const column = primaryColumns?.[key];
            if (column?.id) {
              if (!LOW_PRIORITY_SORT_TYPES.has(column.columnType)) {
                return { column: column.id, asc: true };
              }
              anyColumn = column.id;
            }
          }
          return { column: anyColumn, asc: true };
        },
        codeEditorMode: EditorModes.JSON,
        hidden: (props: TableWidgetProps) =>
          props.pageType === PaginationTypes.SERVER_SIDE,
        panelConfig: {
          editableTitle: false,
          isDeletable: true,
          title: "Default sort",
          panelIdPropertyName: "id",
          children: [
            {
              sectionName: "Default Sort Control",
              children: [
                {
                  propertyName: "column",
                  label: "Column",
                  controlType: "SELECT_TABLE_COLUMN",
                  isBindProperty: false,
                  isTriggerProperty: false,
                },
                {
                  propertyName: "asc",
                  label: "Order",
                  controlType: "RADIO_BUTTON_GROUP",
                  options: [
                    {
                      value: true,
                      icon: SORT_ICONS.Ascending,
                    },
                    {
                      value: false,
                      icon: SORT_ICONS.Descending,
                    },
                  ],
                  defaultValue: true,
                  isBindProperty: false,
                  isTriggerProperty: false,
                  hidden: (props: TableWidgetProps) =>
                    !props.defaultSort?.column,
                },
              ],
            },
          ],
        },
      },
      {
        propertyName: "defaultSearchText",
        label: "Default search",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter search",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "Koala",
      },
      {
        propertyName: "searchPlaceholder",
        label: "Search placeholder",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter search placeholder text",
        isBindProperty: true,
        isTriggerProperty: false,
        defaultValue: "Search",
        visibility: "SHOW_NAME",
        isRemovable: true,
      },
    ],
  },
  {
    sectionName: "Aspect",
    sectionCategory: PropsPanelCategory.Appearance,
    children: [
      {
        helpText: "Selects the default selected row",
        propertyCategory: PropsPanelCategory.Interaction,
        propertyName: "defaultSelectedRow",
        label: "Default selected row",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter row index",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "0",
      },
      {
        helpText: "Show lines in table to divide columns",
        propertyName: "showColumnBorders",
        propertyCategory: PropsPanelCategory.Appearance,
        label: "Column borders",
        controlType: "SWITCH",
        defaultValue: false,
        isBindProperty: false,
        isTriggerProperty: false,
      },
      ...typographyProperties({
        defaultVariant: DEFAULT_HEADING_TEXT_STYLE_VARIANT,
        textStyleParentDottedPath: "headerProps",
        propertyNameForHumans: "Header",
        hiddenIfPropertyNameIsNullOrFalse: "tableHeader",
      }),
      ...typographyProperties({
        defaultVariant: TABLE_COLUMN_HEADER_DEFAULT_TYPOGRAPHY,
        textStyleParentDottedPath: "columnHeaderProps",
        propertyNameForHumans: "Column header",
      }),
      textStyleCombinedProperty({
        textStyleParentDottedPath: "cellProps",
        label: "Cell text style",
        helpText:
          "Sets the style of the data displayed in the table. Can be overridden at the column level",
        optionsCustomizer: tableCellTextStyleOptionsCustomizer,
        defaultValueFn: {
          variant: ({ props }) => {
            if (props.textSize != null && getTableVariant(props) == null) {
              return getTextSizeOptions().find((option) => {
                return option.value === props.textSize;
              })?.value;
            } else {
              return DEFAULT_CELL_TEXT_STYLE_VARIANT;
            }
          },
        },
        themeValue: {
          "textColor.default": ({ props, theme }) => {
            const tableVariant = getTableVariant(props);

            if (themeHasVariant(theme, tableVariant)) {
              return {
                value: `typographies.${tableVariant}.textColor.default`,
                treatAsNull: false,
              };
            } else {
              return {
                value: `typographies.${DEFAULT_CELL_TEXT_STYLE_VARIANT}.textColor.default`,
                treatAsNull: false,
              };
            }
          },
        },
      }),
      ...typographyProperties({
        defaultVariant: DEFAULT_INPUT_TEXT_STYLE_VARIANT,
        textStyleParentDottedPath: "searchProps",
        propertyNameForHumans: "Search input",
        hiddenIfPropertyNameIsNullOrFalse: "isSearchable",
      }),
      ...styleProperties({
        propertyNamespaceDottedPath: "searchProps",
        labelNamespace: "Search",
        hidden: (props: TableWidgetProps) => !props.isSearchable,
        backgroundColorDefaultValue: "{{ theme.colors.neutral }}",
        borderThemeValue: DEFAULT_INPUT_BORDER_OBJECT,
        borderRadiusThemeValue: ({ theme }) => {
          const themeDefault = createPerCornerBorderRadius(theme.borderRadius);

          return {
            value: themeDefault,
            treatAsNull:
              themeDefault == null || equal(themeDefault, EMPTY_RADIUS),
          };
        },
      }),
      backgroundColorProperty({
        themeValue: "{{ theme.colors.neutral }}",
      }),
      backgroundColorProperty({
        propertyName: "selectedRowBackgroundColor",
        label: "Selected row background color",
        helpText: "Changes the background color of the selected row",
        themeValue: "{{ theme.colors.neutral50 }}",
      }),
      borderProperty({
        themeValue: DEFAULT_CONTAINER_BORDER_OBJECT,
      }),
      borderRadiusProperty({
        defaultValue: EMPTY_RADIUS,
        themeValue: ({ theme }) => {
          const themeDefault = createPerCornerBorderRadius(theme.borderRadius);

          return {
            value: themeDefault,
            treatAsNull:
              themeDefault == null || equal(themeDefault, EMPTY_RADIUS),
          };
        },
      }),
      {
        helpText: "Change table view density",
        propertyName: "compactMode",
        label: "Row density",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "Extra small",
            value: CompactModeTypes.VERY_SHORT,
          },
          {
            label: "Small",
            value: CompactModeTypes.SHORT,
          },
          {
            label: "Medium",
            value: CompactModeTypes.DEFAULT,
          },
          {
            label: "Large",
            value: CompactModeTypes.TALL,
          },
        ],
        defaultValue: CompactModeTypes.DEFAULT,
        isBindProperty: false,
        isTriggerProperty: false,
      },
      {
        helpText:
          "Sets the maximum number of lines a row can take up. This setting only affects cells that wrap text",
        propertyName: "maxLinesPerRow",
        label: "Row max lines",
        controlType: "DROP_DOWN",
        defaultValue: -1,
        isBindProperty: false,
        isTriggerProperty: false,
        isJSConvertible: true,
        visibility: "SHOW_NAME",
        isRemovable: true,
        validation: VALIDATION_TYPES.NUMBER,
        options: [
          {
            label: "No limit",
            value: -1,
          },
          {
            label: "1",
            value: 1,
          },
          {
            label: "2",
            value: 2,
          },
          {
            label: "3",
            value: 3,
          },
          {
            label: "4",
            value: 4,
          },
          {
            label: "5",
            value: 5,
          },
        ],
      },
      {
        helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
        propertyName: "animateLoading",
        isJSConvertible: true,
        label: "Loading animation",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
      },
      ...visibleProperties({ useJsExpr: false }),
    ],
  },
  {
    sectionName: "Event handlers",
    sectionCategory: PropsPanelCategory.EventHandlers,
    showHeader: true,
    headerType: "Add",
    children: [
      getPopoverConfig(
        "onRowClicked",
        "Triggers an action when a table row is clicked",
        {},
        {
          customJSControl: "COMPUTE_TABLE_VALUE",
          updateHook: updateDerivedColumnsHook,
        },
      ),
      getPopoverConfig(
        "onSaveChanges",
        "Triggers an action when table edits are saved",
        {},
        {
          customJSControl: "COMPUTE_TABLE_VALUE",
          updateHook: updateDerivedColumnsHook,
        },
      ),
      getPopoverConfig(
        "onCancelChanges",
        "Triggers an action when table edits are canceled",
        {},
        {
          customJSControl: "COMPUTE_TABLE_VALUE",
          updateHook: updateDerivedColumnsHook,
        },
      ),
      getPopoverConfig(
        "onRowSelected",
        "Triggers an action when table row selection is changed",
        {
          visibility: "IF_VALUE",
        },
        {
          customJSControl: "COMPUTE_TABLE_VALUE",
          updateHook: updateDerivedColumnsHook,
        },
      ),
      getPopoverConfig(
        "onPageChange",
        "Triggers an action when a table page is changed",
        { visibility: "IF_VALUE" },
        {
          customJSControl: "COMPUTE_TABLE_VALUE",
          updateHook: updateDerivedColumnsHook,
        },
      ),
      getPopoverConfig(
        "onFiltersChanged",
        "Triggers an action when a table filter is changed",
        {
          visibility: "IF_VALUE",
          hidden: (props: TableWidgetProps) =>
            props.isFilterable === false ||
            props.pageType === PaginationTypes.SERVER_SIDE,
        },
        {
          customJSControl: "COMPUTE_TABLE_VALUE",
          updateHook: updateDerivedColumnsHook,
        },
      ),
    ],
  },
];

export default config;
