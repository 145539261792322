import { all } from "redux-saga/effects";
import apiInfoSagas from "legacy/sagas/ApiInfoSagas";
import appAndPagePersistence from "legacy/sagas/AppAndPageSavingSagas";
import applicationSagas from "legacy/sagas/ApplicationSagas";
import dynamicFormSagas from "legacy/sagas/DynamicFormSagas";
import editorPreferencesSagas from "legacy/sagas/EditorPreferencesSagas";
import embeddingSagas from "legacy/sagas/EmbeddingSagas";
import errorSagas from "legacy/sagas/ErrorSagas";
import evaluationsSaga, {
  evaluateBindingsDirectlyListenerSaga,
  undoRedoListenerSaga,
} from "legacy/sagas/EvaluationsSaga";
import eventSagas from "legacy/sagas/EventSagas";
import modalSagas from "legacy/sagas/ModalSagas";
import pageLoadSagas from "legacy/sagas/PageLoadSagas";
import pageSagas from "legacy/sagas/PageSagas";
import routeSagas from "legacy/sagas/RouteSagas";
import selectionCanvasSagas from "legacy/sagas/SelectionCanvasSagas";
import stateVarSagas from "legacy/sagas/StateVarSagas";
import tabsSagas from "legacy/sagas/TabsSagas";
import themeSagas from "legacy/sagas/ThemeSagas";
import timerSagas from "legacy/sagas/TimerSagas";
import { watchTriggerSagas } from "legacy/sagas/TriggerExecutionSaga";
import userSagas from "legacy/sagas/UserSagas";
import actionExecutionChangeListeners from "legacy/sagas/WidgetLoadingSaga";
import widgetOperationSagas from "legacy/sagas/WidgetOperationSagas";
import widgetResizeSagas from "legacy/sagas/WidgetResizeSagas";
import localDevServerStatusSaga from "legacy/sagas/localDevServerStatusSaga";
import { createBranchSaga } from "./sagas/createBranch";
import { executeV2ApiWithViewModeSaga } from "./sagas/executeV2ApiWithViewModeSaga";
import initializeUserSaga, { initializeVisitor } from "./sagas/initializeUser";
import initializeWorkflowEditor from "./sagas/initializeWorkflowEditor";
import renameApplicationEntitySaga from "./sagas/renameApplicationEntity";
import agentsSlice from "./slices/agents";
import apisSlice from "./slices/apis";
import formatV2ApiStepsSaga from "./slices/apisV2/formatCodeSaga";
import apiPermissionsSaga from "./slices/apisV2/sagas/getApiPermission";
import { apiUndoRedoListenerSaga } from "./slices/apisV2/sagas/replay";
import apisV2Slice from "./slices/apisV2/slice";
import { getAvailableWidgetsSaga } from "./slices/availableWidgets";
import commitsSlice from "./slices/commits";
import datasourcesSlice from "./slices/datasources";
import deploymentsSlice from "./slices/deployments";
import featureFlags from "./slices/featureFlags";
import organizationsSlice from "./slices/organizations";
import repoConnectionsSlice from "./slices/repositories";
import userSlice from "./slices/user";
import { initializeSaga, spawnSaga } from "./utils/effects";

const ROOT_SAGAS = [
  initializeUserSaga,
  initializeVisitor,
  initializeWorkflowEditor,
  getAvailableWidgetsSaga,
  renameApplicationEntitySaga,
  executeV2ApiWithViewModeSaga,
  createBranchSaga,
  formatV2ApiStepsSaga,
];

const LEGACY_SAGAS = [
  agentsSlice.rootSaga,
  organizationsSlice.rootSaga,
  apisSlice.rootSaga,
  apisV2Slice.rootSaga,
  datasourcesSlice.rootSaga,
  featureFlags.rootSaga,
  userSlice.rootSaga,
  commitsSlice.rootSaga,
  repoConnectionsSlice.rootSaga,
  deploymentsSlice.rootSaga,
  pageSagas,
  pageLoadSagas,
  watchTriggerSagas,
  widgetOperationSagas,
  widgetResizeSagas,
  errorSagas,
  applicationSagas,
  userSagas,
  modalSagas,
  tabsSagas,
  themeSagas,
  timerSagas,
  stateVarSagas,
  editorPreferencesSagas,
  apiInfoSagas,
  evaluationsSaga,
  actionExecutionChangeListeners,
  undoRedoListenerSaga,
  selectionCanvasSagas,
  apiUndoRedoListenerSaga,
  localDevServerStatusSaga,
  evaluateBindingsDirectlyListenerSaga,
  dynamicFormSagas,
  embeddingSagas,
  routeSagas,
  eventSagas,
  apiPermissionsSaga,
  appAndPagePersistence,
];

export default function* rootSaga() {
  yield all([
    ...LEGACY_SAGAS.map(spawnSaga),
    ...ROOT_SAGAS.map(initializeSaga),
  ]);
}
