import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const LabelCssString = `
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

const SubTextCssString = `
  color: ${colors.GREY_400};
  font-size: 11px;
  line-height: 16px;
`;

export const Heading3 = styleAsClass`
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: ${colors.GREY_900};
`;

// for help info
export const Dashed = styleAsClass`
  border-bottom: 1px dashed ${colors.GREY_200};
  cursor: pointer;
  width: fit-content;
`;

export const Label = styleAsClass`
  ${LabelCssString}
`;

export const SubText = styleAsClass`
 ${SubTextCssString}
 `;

export const FormWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .form-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .form-item-label {
    ${LabelCssString}
  }
 
  .form-item-subtext {
    ${SubTextCssString}
  }
 
  .form-item-error {
    color: ${colors.RED_500};
    font-size: 12px;
  }

  .form-item-with-error .form-value-wrapper > span {
    border-color: ${colors.RED_500} !important;
    box-shadow: none !important;
  }

  .ant-select {
    width: 100%;
  }
`;

export const DescriptionContainer = styleAsClass`
  font-weight: 400;
  color: ${colors.GREY_400};
  line-height: 16px;
  font-size: 11px;
  margin-top: 4px;
`;

export const FormItem = ({
  label,
  children,
  required,
  error,
  subText,
  hidden,
  style,
}: {
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  required?: boolean;
  error?: string | React.ReactNode;
  subText?: string | React.ReactNode;
  hidden?: boolean;
  style?: React.CSSProperties;
}) => {
  if (hidden) return null;
  return (
    <div
      style={style}
      className={`form-item ${error ? "form-item-with-error" : ""}`}
    >
      {label && (
        <div className="form-item-label">
          {label} {required && <span style={{ color: colors.RED_500 }}>*</span>}
        </div>
      )}
      {children && <div className={`form-value-wrapper`}>{children}</div>}
      {subText && <div className="form-item-subtext">{subText}</div>}
      {error && <div className="form-item-error">{error}</div>}
    </div>
  );
};
