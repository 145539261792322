import { ApplicationScope, TriggerStepType } from "@superblocksteam/shared";
import { isEqual, uniq } from "lodash";
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import {
  pageLoadActionsComplete,
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  updateCachedData,
  updateCurrentRoute,
} from "legacy/actions/pageActions";
import { runEventHandlers } from "legacy/actions/widgetActions";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { EventType } from "legacy/constants/ActionConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getPageCachedData } from "legacy/selectors/sagaSelectors";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { createRunEventHandlersPayload } from "legacy/utils/actions";
import {
  markPageLoadV2Apis,
  selectPageLoadV2Apis,
  selectV2ApiNameToIdMap,
  selectV2ApisByIds,
} from "store/slices/apisV2";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import {
  getV2ApiId,
  getV2ApiName,
} from "store/slices/apisV2/utils/getApiIdAndName";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { SagaReturnValue } from "store/utils/saga";
import { GeneratorReturnType } from "store/utils/types";
import { NOOP } from "utils/function";
import logger from "utils/logger";
import {
  apiProps,
  apiToApiDep,
  getAllApiBindings,
} from "./EvaluationsSagaHelpers";
import { runEventHandlersSaga } from "./TriggerExecutionSaga";
import { setLoadingEntitiesFromApis } from "./WidgetLoadingSaga";
import { DryRunMigrationManger } from "./onLoadMigration/migrateServerDSL";

// TODO: This function operates on groups of actions instead of a tree structure
// This could be problematic if some of the pageload actions take a long time
// the user impact would be that the data would be in a loading state for longer
function* sequentialPageLoad(
  apis: ApiDtoWithPb[],
  loadingGraph: Record<string, string[]>,
): Generator<any, void, any> {
  const completedApiNames = new Set<string>();
  try {
    // This needs to fire first because as soon as the iframe received the STARTED_PAGE_LOAD_APIS action
    // it will render some components without loading state.
    yield call(
      setLoadingEntitiesFromApis,
      apis.map((api) => getV2ApiName(api)),
    );
    yield put({ type: ReduxActionTypes.STARTED_PAGE_LOAD_APIS });

    const apiNameToId: Record<string, string> = {};
    apis.forEach(
      (api) => (apiNameToId[getV2ApiName(api) ?? ""] = getV2ApiId(api) ?? ""),
    );

    const firstApis = apis.filter(
      (api) => !loadingGraph[getV2ApiName(api)]?.length,
    );
    if (firstApis.length > 0) {
      logger.debug(
        `pageLoad first batch ${firstApis
          .map((api) => getV2ApiName(api))
          .join(", ")}`,
      );
      // Load all sets in parallel
      const callbackId = addNewPromise(NOOP);
      const apiNames = firstApis.map((api) => getV2ApiName(api));
      yield call(
        runEventHandlersSaga,
        runEventHandlers(
          createRunEventHandlersPayload({
            steps: [
              {
                id: "0",
                type: TriggerStepType.RUN_APIS,
                apiNames,
              },
            ],
            type: EventType.ON_PAGE_LOAD,
            entityName: "Page",
            currentScope: ApplicationScope.PAGE,
            triggerLabel: `onPageLoad`,
            callbackId,
          }),
        ),
      );

      firstApis.forEach((api) =>
        completedApiNames.add(getV2ApiName(api) ?? ""),
      );
    } else {
      logger.debug("pageLoad no APIs");
    }
    while (completedApiNames.size < apis.length) {
      const canBeRun: string[] = [];
      Object.entries(loadingGraph).forEach(([apiName, dependsOn]) => {
        if (!completedApiNames.has(apiName)) {
          const canRun = Array.from(dependsOn).every((depName) =>
            completedApiNames.has(depName),
          );
          if (canRun) {
            canBeRun.push(apiName);
          }
        }
      });
      if (canBeRun.length) {
        logger.debug(`pageLoad next batch ${canBeRun.join(", ")}`);
        yield all(
          canBeRun.map(function* (name): Generator<any, void, any> {
            const callbackId = addNewPromise(NOOP);
            yield call(
              runEventHandlersSaga,
              runEventHandlers(
                createRunEventHandlersPayload({
                  steps: [
                    {
                      id: "0",
                      type: TriggerStepType.RUN_APIS,
                      apiNames: [name],
                    },
                  ],
                  type: EventType.ON_PAGE_LOAD,
                  entityName: "Page",
                  currentScope: ApplicationScope.PAGE,
                  triggerLabel: `onPageLoad`,
                  callbackId,
                }),
              ),
            );
            completedApiNames.add(name);
          }),
        );
      } else {
        logger.debug(`pageLoad no second batch`);
        return;
      }
    }
  } catch (e) {
    logger.error(e);

    Toaster.show({
      text: "Failed to load onPageLoad actions",
      variant: Variant.danger,
    });
  }
}

/**
 * updates (if necessary) the cached data in the store
 * @returns true if the cached data was updated, false otherwise
 */
export function* cachePageLoadActionsInfo() {
  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (appMode && appMode !== APP_MODE.EDIT) {
    return false;
  }
  const pageLoadActionsInfo: SagaReturnValue<typeof getPageLoadActionsInfo> =
    yield call(getPageLoadActionsInfo);

  const pageLoadActions = {
    apiNames: pageLoadActionsInfo.loadApis.map(
      (api) => getV2ApiName(api) ?? "",
    ),
    apiDeps: pageLoadActionsInfo.apiToApiDependencies,
  };
  const cachedData: ReturnType<typeof getPageCachedData> = yield select(
    getPageCachedData,
  );
  if (isEqual(pageLoadActions, cachedData?.pageLoadActions)) {
    // no changes, no need to update the cached data
    return false;
  }
  yield put(updateCachedData({ pageLoadActions }));
  return true;
}

function* getPageLoadGraph(): Generator<any, Record<string, string[]>, any> {
  // This map includes _all_ dependencies, but not all dependencies need to be part of the pageLoad
  // We are automatically running any API that is displayed in the UI, unless the user disables this
  // try {
  const loadApiBindings = yield* getAllApiBindings();
  const { allApiProps, loadApiProps } = yield* apiProps();

  const apiToApiDependencies = yield* apiToApiDep(
    loadApiBindings,
    allApiProps,
    loadApiProps,
  );
  const apiToApiDependenciesArr = Object.fromEntries(
    Object.entries(apiToApiDependencies).map(([name, deps]) => [
      name,
      Array.from(deps),
    ]),
  );
  return apiToApiDependenciesArr;
}

function* getPageLoadActionsInfo() {
  const apiToApiDependencies: Record<string, string[]> = yield call(
    getPageLoadGraph,
  );
  // Remove any APIs that are not part of the pageLoad graph (I.e. they have circular dependencies)
  const pageLoadV2Apis: ReturnType<typeof selectPageLoadV2Apis> = yield select(
    selectPageLoadV2Apis,
  );
  const loadApis = pageLoadV2Apis.filter(
    (api) => apiToApiDependencies[getV2ApiName(api) ?? ""],
  );

  return { loadApis, apiToApiDependencies };
}

export function* markPageLoadApis() {
  let loadApis: ApiDtoWithPb[];
  let apiToApiDependencies: Record<string, string[]>;
  const cachedData: ReturnType<typeof getPageCachedData> = yield select(
    getPageCachedData,
  );
  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (appMode && appMode !== APP_MODE.EDIT && cachedData?.pageLoadActions) {
    // We are not in edit mode, and we have cached data about the page load actions. Use that.
    const apiNameMap: Record<string, string> = yield select(
      selectV2ApiNameToIdMap,
    );
    const apiIds = cachedData.pageLoadActions.apiNames.map(
      (apiName) => apiNameMap[apiName],
    );
    loadApis = yield select((state) =>
      selectV2ApisByIds(state, apiIds).filter(Boolean),
    );
    apiToApiDependencies = cachedData.pageLoadActions.apiDeps;
  } else {
    // We are in edit mode, or we don't have cached data about the page load actions. Extract that data from
    // the static analysis.
    const pageLoadActionsInfo: SagaReturnValue<typeof getPageLoadActionsInfo> =
      yield call(getPageLoadActionsInfo);
    loadApis = pageLoadActionsInfo.loadApis;
    apiToApiDependencies = pageLoadActionsInfo.apiToApiDependencies;
  }
  const pageActionIds = uniq(loadApis.map((api) => getV2ApiId(api)));

  if (pageActionIds.length) {
    // Make sure all apis are in a loading state
    yield put(markPageLoadV2Apis.create(Array.from(pageActionIds)));
  }

  DryRunMigrationManger.compareToDryRun(loadApis);

  return { loadApis, apiToApiDependencies };
}

export function* executePageLoadActions({
  loadApis,
  apiToApiDependencies,
}: {
  loadApis: ApiDtoWithPb[];
  apiToApiDependencies: Record<string, string[]>;
}) {
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
    { numActions: loadApis.length },
  );
  if (loadApis.length) {
    // Make sure all apis are in a loading state
    yield race({
      completed: call(sequentialPageLoad, loadApis, apiToApiDependencies),
      leftApp: take(stopEvaluation.type),
      leftPage: take(restartEvaluation.type),
      changedPath: take(updateCurrentRoute.type),
    });
  } else {
    // Mark as completed
    yield put({ type: ReduxActionTypes.STARTED_PAGE_LOAD_APIS });
  }

  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
  );
}

function* markAndRunPageLoadActions() {
  const result: GeneratorReturnType<typeof markPageLoadApis> = yield call(
    markPageLoadApis,
  );
  yield call(executePageLoadActions, result);
  yield put(pageLoadActionsComplete);
}

export default function* pageLoadSagas() {
  yield all([
    takeEvery(
      ReduxActionTypes.MARK_AND_RUN_PAGE_LOAD_APIS,
      markAndRunPageLoadActions,
    ),
  ]);
}
