import {
  MenuItem,
  Classes,
  Menu,
  MenuDivider,
  MaybeElement,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  MultiSelect,
  ItemRendererProps,
  Classes as MultiSelectClasses,
  ItemListRendererProps,
  SelectPopoverProps,
} from "@blueprintjs/select";
import { DropdownOption as DropdownOptionShared } from "@superblocksteam/shared";
import Fuse from "fuse.js";
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import styled from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { useElementRect } from "hooks/ui";
import { BlueprintInputTransform } from "legacy/constants/DefaultTheme";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { Checkbox } from "./Checkbox";
import { SearchInput } from "./SearchSection";

const MAX_RENDER_MENU_ITEMS_HEIGHT = 292;
const EXTRA_PER_ITEM = 2;
const EXTRA_PER_DIVIDER = 4;
const EXTRA_PER_GROUP_HEADER = 12;
const SUBTEXT_FONT_WEIGHT = 400;

const PopoverClassName = "select-popover-wrapper";
const MultiSelectWrapperClass = "multi-select-wrapper-class";

export type DropdownOption<T = any> = DropdownOptionShared & {
  disabled?: boolean;
  style?: React.CSSProperties;
  dataTest?: string;
  optionData?: T;
  icon?: MaybeElement;
};

const StyledChevronDown = styled(ChevronDown)<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "")};
  position: absolute;
  right: 8px;
  top: 3px;
  & path {
    stroke: ${({ theme, $isOpen }) =>
      $isOpen ? theme.colors.ACCENT_BLUE_500 : theme.colors.GREY_300};
  }
`;

const MultiDropDown = MultiSelect.ofType<DropdownOption>();

const StyledMultiDropDown = styled(MultiDropDown)<{
  $isOpen: boolean;
  $hideTags: boolean; // show customized selected items instead of default tags
}>`
  color: ${colors.GREY_700};
  div:first-child {
    flex: 1 1 auto;
    .${MultiSelectClasses.MULTISELECT} {
      position: relative;
      min-width: 0;
      min-height: 0;
    }
  }

  // Negative margin
  margin-right: 1px;

  && {
    ${BlueprintInputTransform}
    .${Classes.TAG_INPUT}::-webkit-scrollbar {
      display: none;
    }
    .${Classes.TAG_INPUT} {
      font-size: 12px;
      border-radius: 4px !important;
      display: flex;
      width: 100%;
      min-width: 0;
      align-items: center;
      justify-content: space-between;
      text-overflow: ellipsis;
      overflow: auto;
      padding-left: 3px;
      border-color: ${({ theme, $isOpen }) =>
        $isOpen
          ? theme.colors.ACCENT_BLUE_500
          : theme.colors.GREY_100} !important;
      min-height: 32px;

      .${Classes.TAG_INPUT_VALUES} {
        margin-top: 2px;
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        scrollbar-width: none;
        // not using display none because click event is registered on input values
        ${({ $hideTags }) => ($hideTags ? "opacity: 0;" : "")}
        max-height: 119px; // show four rows max
        overflow-y: auto;
      }

      .${Classes.TAG} {
        background: none;
        border: 1px solid ${(props) => props.theme.colors.GREY_100};
        border-radius: 3px;
        height: 24px;
        font-size: 12px;
        color: ${(props) => props.theme.colors.GREY_700};
        padding: 4px 8px;
        margin-bottom: 3px;
        margin-right: 3px;
        svg[data-icon="small-cross"] path {
          fill: ${(props) => props.theme.colors.GREY_300};
        }
        ${({ $hideTags }) => ($hideTags ? "display: none;" : "")}
      }

      & > .${Classes.ICON} {
        align-self: center;
        margin-right: 0px;
        color: ${LegacyNamedColors.SLATE_GRAY};
      }
      .${Classes.INPUT_GHOST}:first-child {
        padding-left: 9px;
      }
      .${Classes.INPUT_GHOST} {
        margin: 0;
        display: flex;
        height: 26px;
        flex: 1;
        padding-left: 5px;
      }
    }
  }
`;

const MenuWrapper = styleAsClass`
  .${Classes.MENU} {
    & li {
      padding: 0px 4px;
    }
  }
  .${Classes.MENU_ITEM} {
    display: flex;
    align-items: center;
  }
  .${Classes.MENU_ITEM_ICON} {
    margin-left: 10px;
  }
  .${Classes.MENU_HEADER} {
    border-top: unset;
    width: 100%;
    padding-left: 14px;
    margin: 8px 0px;
    padding
  }
`;

const OptionTextWrapper = styleAsClass`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubText = styleAsClass`
  color: ${colors.GREY_500};
  $[data-disabled="true"] {
    color: inherit;
  }
  font-size: 12px;
  font-weight: ${String(SUBTEXT_FONT_WEIGHT)};
  white-space: normal;
  padding-left: 24px;
`;

const SELECT_ALL_IDENTIFIER = "dropdown-multi-select-item-all";
const SelectAllOption = {
  displayName: "All",
  value: SELECT_ALL_IDENTIFIER,
  key: SELECT_ALL_IDENTIFIER,
  hasDivider: true,
};

export const RecommendedMultiDropdown = <T,>({
  options,
  placeholder,
  disabled,
  selectedItems,
  dataTest,
  parentRef,
  style,
  onChange,
  disableSearch,
  resetOnQuery,
  resetOnSelect,
  showSearchInPopover,
  renderSelectedItems,
  width,
  enableSelectAll,
  queryInput,
  onQueryChange,
  onFilterItems,
  onInputKeyDown,
  onInputFocus,
  onInputBlur,
  noResultsMessage,
  hideNoResultsMessage,
  popoverProps,
  hideCheckbox,
  customPredicate,
  rightElement,
}: {
  options?: DropdownOption[];
  placeholder?: string;
  // Must be a controlled value
  selectedItems: DropdownOption[];
  disabled?: boolean;
  dataTest?: string;
  onChange: (options: DropdownOption[]) => void;
  parentRef?: React.RefObject<HTMLElement>;
  style?: React.CSSProperties;
  disableSearch?: boolean;
  resetOnSelect?: boolean;
  resetOnQuery?: boolean;
  showSearchInPopover?: boolean;
  renderSelectedItems?: (selectedItems: DropdownOption<T>[]) => JSX.Element;
  width?: number;
  enableSelectAll?: boolean;
  queryInput?: string;
  onQueryChange?: (query: string) => void;
  onFilterItems?: (items: DropdownOption[]) => void;
  onInputKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onInputFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  noResultsMessage?: JSX.Element | string;
  hideNoResultsMessage?: boolean;
  popoverProps?: SelectPopoverProps["popoverProps"];
  hideCheckbox?: boolean;
  customPredicate?: (
    query: string,
    items: DropdownOption[],
  ) => DropdownOption[];
  rightElement?: JSX.Element;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRect = useElementRect(wrapperRef);

  const [listHeight, setListHeight] = useState(
    MAX_RENDER_MENU_ITEMS_HEIGHT + EXTRA_PER_ITEM,
  );
  const listRef = useRef<VirtuosoHandle>(null);

  const [queryLocal, setQueryLocal] = useState("");
  const query = queryInput ?? queryLocal;
  const setQuery = onQueryChange ?? setQueryLocal;

  const optionWithAll = useMemo(
    () =>
      enableSelectAll && options ? [SelectAllOption, ...options] : options,
    [enableSelectAll, options],
  );

  const isAllSelected = selectedItems?.length === options?.length;
  const nonSelected = selectedItems?.length === 0;

  const [isOpen, setIsOpen] = React.useState(false);

  const renderTag = (option: DropdownOption) => {
    return option.displayName;
  };

  const isOptionSelected = useCallback(
    (selectedOption: DropdownOption) =>
      selectedItems.find((item) => item.value === selectedOption.value) !==
      undefined,
    [selectedItems],
  );

  const fuse = useMemo(() => {
    const fuse = new Fuse(optionWithAll ?? [], {
      shouldSort: true,
      threshold: 0.3,
      ignoreLocation: true,
      minMatchCharLength: 1,
      findAllMatches: true,
      keys: ["displayName", "value"],
    });
    return fuse;
  }, [optionWithAll]);

  const itemListPredicate = useCallback(
    (query: string, items: DropdownOption[]) => {
      if (disableSearch) {
        return items;
      }
      const filteredItems = query
        ? fuse.search(query).map(({ item }) => item)
        : items;
      onFilterItems?.(filteredItems);
      return filteredItems;
    },
    [disableSearch, fuse, onFilterItems],
  );

  const renderMultiSelectItem = useCallback(
    (option: DropdownOption, itemProps: ItemRendererProps) => {
      if (!itemProps.modifiers.matchesPredicate) {
        return null;
      }
      const isSelected: boolean = isOptionSelected(option);

      if (option.isGroupHeader) {
        return <MenuDivider title={option.displayName} />;
      }
      return (
        <>
          <MenuItem
            className={`multi-select ${isSelected ? "selected" : ""}`}
            active={itemProps.modifiers.active}
            key={option.value}
            icon={option.icon}
            text={
              <div
                data-test={`dropdown-multi-select-item-${option.displayName}`}
                style={{ padding: "6px 8px" }}
              >
                {hideCheckbox ? (
                  <div title={option.displayName} className={OptionTextWrapper}>
                    {option.displayName ?? ""}
                  </div>
                ) : (
                  <Checkbox
                    checked={
                      isSelected ||
                      (option.value === SELECT_ALL_IDENTIFIER && isAllSelected)
                    }
                    partialChecked={
                      option.value === SELECT_ALL_IDENTIFIER &&
                      !isAllSelected &&
                      !nonSelected
                    }
                  >
                    <div
                      title={option.displayName}
                      className={OptionTextWrapper}
                    >
                      {option.displayName ?? ""}
                    </div>
                  </Checkbox>
                )}
                {option.subText && (
                  <div
                    className={SubText}
                    data-disabled={option.disabled}
                    style={option.icon ? { paddingLeft: 0 } : undefined}
                  >
                    {option.subText}
                  </div>
                )}
              </div>
            }
            onClick={itemProps.handleClick}
          />
          {option.hasDivider && (
            <div
              style={{
                borderTop: `1px solid ${colors.GREY_50}`,
                margin: "2px 0 0 -4px",
                width: "calc(100% + 4px)",
              }}
            ></div>
          )}
        </>
      );
    },
    [hideCheckbox, isAllSelected, isOptionSelected, nonSelected],
  );

  const itemListRenderer = useCallback(
    ({
      filteredItems,
      itemsParentRef,
      renderItem,
    }: ItemListRendererProps<DropdownOption>) => {
      const itemsToRender: DropdownOption[] = filteredItems;

      const menuStyle: React.CSSProperties = {
        width: wrapperRect?.width ?? "100%",
        padding: "2px 0",
      };

      const noResultsMessageToShow = noResultsMessage ?? "No results found";

      if (!filteredItems?.length && hideNoResultsMessage) {
        return null;
      }

      const numOfDivider =
        filteredItems?.filter((option) => option.hasDivider).length ?? 0;
      const numOfGroupHeader =
        filteredItems?.filter((option) => option.isGroupHeader).length ?? 0;

      return (
        <div className={MenuWrapper}>
          {/* a search section if the selectedItems are rendered in the place of input */}
          {renderSelectedItems && showSearchInPopover && (
            <div
              style={{
                padding: 5,
                marginRight: -15,
                marginBottom: -4,
                width: "100%",
              }}
            >
              <SearchInput
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          )}
          <Menu ulRef={itemsParentRef} style={menuStyle}>
            {filteredItems?.length > 0 ? (
              <Virtuoso
                ref={listRef}
                data={itemsToRender}
                totalListHeightChanged={(height) => {
                  const itemCountSpacing =
                    itemsToRender.length * EXTRA_PER_ITEM;
                  const totalHeight =
                    itemCountSpacing +
                    height +
                    numOfDivider * EXTRA_PER_DIVIDER +
                    numOfGroupHeader * EXTRA_PER_GROUP_HEADER;
                  setListHeight(
                    totalHeight < MAX_RENDER_MENU_ITEMS_HEIGHT
                      ? totalHeight
                      : MAX_RENDER_MENU_ITEMS_HEIGHT,
                  );
                }}
                style={{
                  width: "100%",
                  height: listHeight,
                }}
                itemContent={(index, data) => {
                  return renderItem(data, index);
                }}
              ></Virtuoso>
            ) : (
              <div className={MenuWrapper}>
                <Menu ulRef={itemsParentRef} style={menuStyle}>
                  <div style={{ padding: "6px 12px", color: colors.GREY_500 }}>
                    {noResultsMessageToShow}
                  </div>
                </Menu>
              </div>
            )}
          </Menu>
        </div>
      );
    },
    [
      hideNoResultsMessage,
      listHeight,
      noResultsMessage,
      query,
      renderSelectedItems,
      setQuery,
      showSearchInPopover,
      wrapperRect?.width,
    ],
  );

  // used to adjust active item when a new item is selected (otherwise, resetOnSelect will cause the active item to be the first one after selection)
  const itemJustSelected = useRef<DropdownOption | null>(null);
  const isOpenHandledByInputFocus = useRef(false);

  const onItemSelect = (selectedItem: DropdownOption): void => {
    if (selectedItem.value === SELECT_ALL_IDENTIFIER) {
      if (selectedItems.length === options?.length) {
        onChange([]);
      } else {
        onChange(options ?? []);
      }
      resetOnSelect && setQuery("");

      itemJustSelected.current = selectedItem;
      return;
    }
    if (
      selectedItems.find((item) => item.value === selectedItem.value) !==
      undefined
    ) {
      onChange(
        selectedItems.filter((item) => item.value !== selectedItem.value),
      );
    } else {
      onChange([...selectedItems, selectedItem]);
    }

    itemJustSelected.current = selectedItem;
    resetOnSelect && setQuery("");
  };

  const onItemRemoved = (_tag: ReactNode, indexRemoved: number) => {
    const newItems = selectedItems.filter(
      (item, index) => index !== indexRemoved,
    );
    onChange(newItems);
  };

  const [activeItem, setActiveItem] = useState<DropdownOption | null>(null);
  const onActiveItemChange = useCallback(
    (activeItem: DropdownOption | null) => {
      if (itemJustSelected.current) {
        setActiveItem(itemJustSelected.current);
        itemJustSelected.current = null;
      } else {
        setActiveItem(activeItem);
      }
    },
    [itemJustSelected],
  );

  const handleInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (
        e.relatedTarget &&
        (e.relatedTarget.closest(`.${PopoverClassName}`) ||
          (e.relatedTarget.classList.contains(Classes.TAG_REMOVE) && isOpen))
      ) {
        // do not call input focus if event is triggered from popover (e.g. clicking on an item)
        // do not close popover if clicking on tag remove button
        return;
      }
      setIsOpen(!isOpen);
      onInputFocus?.(e);
      isOpenHandledByInputFocus.current = true;
    },
    [isOpen, onInputFocus],
  );

  const handleInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (
        e.relatedTarget &&
        (e.relatedTarget.closest(`.${PopoverClassName}`) ||
          e.relatedTarget.classList.contains(MultiSelectWrapperClass) ||
          e.relatedTarget.classList.contains(Classes.TAG_REMOVE))
      ) {
        // do not close popover if:
        // - event is triggered from popover (e.g. clicking on an item)
        // - clicking inside the input wrapper div
        // - clicking on tag remove button
        return;
      }
      setIsOpen(false);
      onInputBlur?.(e);
    },
    [onInputBlur],
  );

  const handOpenToggle = useCallback(() => {
    if (isOpenHandledByInputFocus.current) {
      // if isOpen is set by handleInputFocus, do not toggle again
      isOpenHandledByInputFocus.current = false;
      return;
    }
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div
      ref={wrapperRef}
      style={style}
      className={MultiSelectWrapperClass}
      tabIndex={-1}
    >
      <div style={{ position: "relative", ...(width ? { width } : {}) }}>
        <StyledMultiDropDown
          $isOpen={isOpen}
          $hideTags={Boolean(renderSelectedItems)}
          items={optionWithAll ?? []}
          scrollToActiveItem={false}
          query={query}
          onQueryChange={setQuery}
          resetOnSelect={resetOnSelect ?? true}
          resetOnQuery={resetOnQuery ?? false}
          itemListPredicate={customPredicate ?? itemListPredicate}
          placeholder={placeholder}
          tagRenderer={renderTag}
          itemRenderer={renderMultiSelectItem}
          itemsEqual={"value"}
          selectedItems={selectedItems}
          itemListRenderer={itemListRenderer}
          tagInputProps={{
            onRemove: onItemRemoved,
            tagProps: (value, index) => ({
              minimal: true,
              interactive: false,
              rightIcon: IconNames.CHEVRON_DOWN,
              "data-test": `${dataTest}-${value}`,
            }),
            disabled: disabled,
            fill: true,
            rightElement:
              rightElement ??
              (renderSelectedItems ? (
                <div
                  style={{
                    position: "absolute",
                    left: "10px",
                    right: 0,
                    display: "flex",
                  }}
                >
                  {renderSelectedItems(selectedItems)}
                  <StyledChevronDown
                    $isOpen={isOpen}
                    data-test="dropdown-toggle-icon"
                    onClick={handOpenToggle}
                  />
                </div>
              ) : (
                <StyledChevronDown
                  $isOpen={isOpen}
                  data-test="dropdown-toggle-icon"
                  style={{ top: "8px" }}
                  onClick={handOpenToggle}
                />
              )),
            inputProps: {
              type: "search",
              "data-test": dataTest,
              className: "inputClassName",
              value: query,
              onClick: handOpenToggle,
              onKeyDown: onInputKeyDown,
              onFocus: handleInputFocus,
              onBlur: handleInputBlur,
            } as any,
          }}
          onItemSelect={onItemSelect}
          popoverProps={{
            // TODO(wylie): this type was removed from Popover2, confirm behavior
            // fill: true,
            minimal: true,
            popoverClassName: PopoverClassName,
            // Allow dropdown to overflow its container and placed based on viewport
            rootBoundary: "viewport",
            hasBackdrop: true,
            usePortal: true,
            portalContainer: parentRef?.current ?? undefined,
            onInteraction: undefined,
            isOpen,
            ...(popoverProps ?? {}),
          }}
          activeItem={activeItem}
          onActiveItemChange={onActiveItemChange}
        />
      </div>
    </div>
  );
};
